import DOMPurify from 'dompurify';

import { login } from 'lib/okta';
import { JsonApiError } from 'types';
import { JsonApiErrorResponse } from './types';

interface RequestWithTimeoutOptions extends RequestInit {
  delay?: number;
}

const requestWithTimeout = async (
  resource: RequestInfo,
  options: RequestWithTimeoutOptions = {}
): Promise<Response> => {
  const { delay = 20000 } = options;
  window.abortController = new AbortController();
  const { signal } = window.abortController;
  const id = setTimeout(() => window.abortController.abort(), delay);
  const response = await fetch(resource, {
    ...options,
    signal,
  });
  clearTimeout(id);
  return response;
};

type fetchResponse = {
  response: Response;
  responseObj: any;
};

type FetchOptions = {
  method?: 'GET' | 'POST' | 'PATCH' | 'DELETE';
  headers?: Headers;
  isFile?: boolean;
  body?: FormData | string;
  delay?: number;
};

const addDefaultFetchOptions = (options: FetchOptions = {}) => {
  if (!options.hasOwnProperty('method')) {
    options.method = 'GET';
  }
  if (!options.hasOwnProperty('headers')) {
    options.headers = new Headers({
      'Content-Type': 'application/json',
    });
  }
  if (options.hasOwnProperty('isFile') && options.isFile) {
    delete options.headers;
  }
  return options;
};

const requestWithTimeoutAndOktaLogin = async (
  resource: RequestInfo,
  options?: FetchOptions
): Promise<fetchResponse> => {
  const opts = addDefaultFetchOptions(options);
  const response = await requestWithTimeout(resource, opts);
  if (response.status === 401) {
    login(window.location.pathname);
  }
  const responseText = await response.clone().text();
  try {
    const responseObj = JSON.parse(responseText);
    if (!response?.ok) {
      throw responseObj || responseText;
    }
    return { response, responseObj };
  } catch (responseObj: any) {
    return { response, responseObj };
  }
};

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const extractJsonApiErrors = async (
  response: Response
): Promise<JsonApiError[]> => {
  const unknownErrors: JsonApiError[] = [
    {
      code: 'UNKNOWN_ERROR',
      title: 'Unknown Error',
    },
  ];
  try {
    return response
      .clone()
      .json()
      .then((data: JsonApiErrorResponse) =>
        Array.isArray(data.errors) ? data.errors : unknownErrors
      );
  } catch (error) {
    console.error('Error extracting JSON API errors:', error);
    return unknownErrors;
  }
};

type ObjectAnyValue = { [key: string]: any };

const sanitize = (obj: ObjectAnyValue): any => {
  const sanitized: ObjectAnyValue = {};
  let type: string;
  for (const [key, value] of Object.entries(obj)) {
    type = typeof value;
    switch (type) {
      case 'string':
        sanitized[key] = DOMPurify.sanitize(value);
        break;
      case 'object':
        if (Array.isArray(value)) {
          sanitized[key] = value.map((item) => sanitize(item));
        } else {
          sanitized[key] = sanitize(value);
        }
        break;
      default:
        sanitized[key] = value;
        break;
    }
  }
  return sanitized;
};

export {
  addDefaultFetchOptions,
  extractJsonApiErrors,
  requestWithTimeout,
  requestWithTimeoutAndOktaLogin,
  sanitize,
  sleep,
};
