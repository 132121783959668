import { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

import useCurrentUser from 'contexts/UserContext';
import { Banner } from './Banner';
import { Button, Toast } from 'Components';
import { mailToEmail } from 'lib/string';
import { banner as bannerApi } from 'api';
import { AlertMessage, Banner as BannerType } from 'types';

const ConfigBanner = () => {
  const {
    bannerIndex,
    banners: bannersLocalStorage,
    setBannerIndex,
    setBanners: setBannersLocalStorage,
  } = useCurrentUser();

  const [show, setShow] = useState(true);
  const [dismissing, setDismissing] = useState(false);
  const [displayBanner, setDisplayBanner] = useState<BannerType | null>(
    bannersLocalStorage[bannerIndex]
  );
  const [cacheBanners, setCacheBanners] =
    useState<BannerType[]>(bannersLocalStorage);
  const [displayBanners, setDisplayBanners] =
    useState<BannerType[]>(bannersLocalStorage);
  const [sanitizedHTML, setSanitizedHTML] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<AlertMessage>('');

  useEffect(() => {
    setDisplayBanners(bannersLocalStorage);
    setCacheBanners(bannersLocalStorage);
    if (bannerIndex > bannersLocalStorage.length - 1) {
      setBannerIndex(0);
    }
  }, [bannersLocalStorage]);

  useEffect(() => {
    setDisplayBanner(displayBanners[bannerIndex]);
  }, [bannerIndex, displayBanners]);

  useEffect(() => {
    setSanitizedHTML(
      displayBanner?.message ? DOMPurify.sanitize(displayBanner.message) : ''
    );
  }, [displayBanner]);

  let dismissTimeout: NodeJS.Timeout;
  let toastTimeout: NodeJS.Timeout;

  const prevIndex = () => {
    const prev = bannerIndex - 1;
    if (prev < 0) {
      // if at the first, loop back to last
      return displayBanners.length - 1;
    } else {
      return prev;
    }
  };
  const nextIndex = () => {
    const next = bannerIndex + 1;
    if (next >= displayBanners.length) {
      // if at the last, loop back to first
      return 0;
    } else {
      return next;
    }
  };

  const removeBanner = (
    removedBannerId: string,
    remainingBanners: BannerType[]
  ) => {
    setBannersLocalStorage(remainingBanners);
    const prev = bannerIndex - 1;
    const targetIndex = prev < 0 ? remainingBanners.length - 1 : prev;
    setBannerIndex(targetIndex);
  };

  const handleClose = async (event: React.MouseEvent<HTMLButtonElement>) => {
    clearTimeout(toastTimeout);
    clearTimeout(dismissTimeout);
    if (displayBanner) {
      setDismissing(true);
      const response = await bannerApi.dismissBanner(displayBanner.id);
      setDismissing(false);
      if (response.ok) {
        // the purpose of the cache banners is to track quick dismissals where the user successively dismisses banners quicker than half a sec
        const withoutDismissedBanner = cacheBanners.filter((item) =>
          displayBanner ? item.id !== displayBanner.id : false
        );
        if (withoutDismissedBanner.length === 0) {
          // dismissing this banner removes the last banner so set banner show to false to initiate the 500ms fade out animation
          setShow(false);
          // the fade out animation takes 500ms, so at 505ms save banners to local storage
          dismissTimeout = setTimeout(() => {
            removeBanner(displayBanner.id, withoutDismissedBanner);
          }, 505);
        } else {
          // immediately save to local storage, no need to wait for animation
          removeBanner(displayBanner.id, withoutDismissedBanner);
        }
        setCacheBanners(withoutDismissedBanner);
      } else {
        setToastMessage(
          'An error occurred while attempting to dismiss this message.'
        );
        setShowToast(true);
        toastTimeout = setTimeout(() => {
          if (showToast) {
            setShowToast(false);
          }
        }, 2000);
      }
    }
  };

  if (displayBanners.length === 0) {
    return <></>;
  } else {
    return (
      <>
        <Banner
          show={show}
          type="info"
          showClasses={['mb-2']}
          dataTestId="config-banner"
          rounded={false}
        >
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: mailToEmail(sanitizedHTML) }}
            />
            <div className="mt-1.5 flex gap-3">
              <Button
                as="button"
                type="tertiary"
                onClick={handleClose}
                isLoading={dismissing}
                isDisabled={dismissing}
                loadingWord="Dismissing"
              >
                Dismiss
              </Button>
              {displayBanners.length > 1 && (
                <>
                  <Button
                    type="tertiary"
                    onClick={() => {
                      setBannerIndex(prevIndex());
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    type="tertiary"
                    onClick={() => {
                      setBannerIndex(nextIndex());
                    }}
                  >
                    Next
                  </Button>
                </>
              )}
            </div>
          </div>
        </Banner>
        <Toast
          open={showToast}
          severity="error"
          message={toastMessage}
          onClose={() => {
            setShowToast(false);
          }}
        />
      </>
    );
  }
};

export { ConfigBanner };
