import classNames from 'classnames';

const controlClasses = (disabled: boolean, linkClassname?: string) => ({
  link: classNames(
    'font-bold flex items-center w-fit',
    {
      'cursor-pointer': !disabled,
      'text-secondary-action-base': !disabled,
      'text-secondary-inactive-light': disabled,
    },
    linkClassname
  ),
  icon: classNames('text-base font-bold', {
    'text-secondary-action-base': !disabled,
    'text-secondary-inactive-light': disabled,
    'saturate-0': disabled,
    'brightness-90': disabled,
  }),
});

export { controlClasses };
