import { useEffect, useState } from 'react';
import classNames from 'classnames';

import {
  disabledFilter,
  ProfileSizeClassesSize,
  profileCircleClasses,
} from 'styles/image';
import { ReactComponent as ImageIcon } from 'Assets/icons/image.svg';

type CircleAvatarProps = {
  photoUrl?: string;
  alt: string;
  size?: ProfileSizeClassesSize;
  className?: string;
};

const CircleAvatar = (props: CircleAvatarProps) => {
  const { photoUrl = '', alt = '', size = 32, className = '' } = props;
  const [loadFailed, setLoadFailed] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [figureClasses, setFigureClasses] = useState([
    ...profileCircleClasses(size),
    'flex',
    'items-center',
    'animate-pulse',
    className,
  ]);

  const getSizeInPixels = (size: ProfileSizeClassesSize): number => {
    const sizeMap = {
      8: 32,
      12: 48,
      18: 72,
      19: 76,
      20: 80,
      28: 112,
      32: 128,
    };
    return sizeMap[size] || 128;
  };

  const pixelSize = getSizeInPixels(size);

  let circleClasses = profileCircleClasses(size);

  const profileImageBaseClasses = [
    ...circleClasses.filter(
      (item) => !['h-20', 'h-18', 'md:h-28', 'h-32', 'md:h-32'].includes(item)
    ),
    'h-auto',
    'transition-all',
    'duration-150',
    'ease-in-out',
  ];

  const emptyClasses = ['w-7', 'h-7', 'm-auto'];

  const [profileImageClassName, setProfileImageClassName] = useState([
    ...profileImageBaseClasses,
    'opacity-0',
  ]);
  const nonLoadingfigureClasses = [
    ...profileCircleClasses(size),
    'flex',
    'items-center',
  ];
  const cleanup = () => {
    setLoaded(false);
    setFigureClasses([
      ...profileCircleClasses(size),
      'flex',
      'items-center',
      'animate-pulse',
    ]);
    setLoadFailed(false);
    setProfileImageClassName([...profileImageBaseClasses, 'opacity-0']);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (photoUrl !== '') {
      const img = new Image();
      img.src = photoUrl;
      img
        .decode?.()
        .then(() => {
          setLoaded(true);
          setFigureClasses(profileCircleClasses(size));
          setProfileImageClassName([
            ...profileImageBaseClasses,
            'opacity-1000',
          ]);
        })
        .catch(() => {
          setLoadFailed(true);
          setFigureClasses(nonLoadingfigureClasses);
        });
    } else {
      setFigureClasses(nonLoadingfigureClasses);
    }
    return () => {
      cleanup();
    };
  }, []);

  return (
    <div className={`overflow-visible ${className}`}>
      <figure
        className={classNames(figureClasses)}
        style={{ backgroundColor: '#d8d8d8' }}
      >
        {photoUrl !== '' && loaded && !loadFailed ? (
          <img
            className={classNames(profileImageClassName)}
            src={photoUrl}
            alt={alt}
            width={pixelSize}
            height={pixelSize}
          />
        ) : (
          size > 8 && (
            <ImageIcon
              className={classNames(emptyClasses)}
              style={disabledFilter}
              width={28}
              height={28}
            />
          )
        )}
      </figure>
    </div>
  );
};

export { CircleAvatar };
