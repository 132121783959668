import {
  Alert,
  Carousel,
  Header3,
  LoaderSmall,
  LoanSummaryTile,
  ReferButton,
  Container,
} from 'Components';
import useCurrentUser from 'contexts/UserContext';
import {
  filterOldFundedLoans,
  loanIsFundedOrInProgress,
  sortLoanDateDescending,
} from 'lib/loans';
import { LoanType } from 'types';

const NoActiveDeals = () => (
  <Container
    containerPaddingAll={false}
    largeScreenWidth=""
    className="mx-4 h-52 md:mx-0 lg:h-auto lg:py-6 bg-white flex items-center flex-row justify-center border border-secondary-inactive-lighter"
  >
    <div className="flex items-center flex-col justify-center gap-4 text-center px-6 lg:px-0">
      <Header3>You have no active closings at this time.</Header3>
      <ReferButton size="large" />
    </div>
  </Container>
);

const DealsInProgress = () => {
  const { loans, loansError, loansLoading } = useCurrentUser();
  const filteredLoans = filterOldFundedLoans(loans).filter((loan) =>
    loanIsFundedOrInProgress(loan)
  );
  const visibleCardCount = 8;
  const dealsInProgressLoans =
    Array.isArray(filteredLoans) && filteredLoans.length
      ? sortLoanDateDescending(filteredLoans).slice(0, visibleCardCount)
      : [];

  const getGridColClasses = (loanCount: number) =>
    loanCount === 1 ? 'sm:pr-4 md:pr-0 grid grid-cols-1' : 'md:grid-cols-2';

  const flatCards = (
    <div
      className={`sm:pl-4 md:pl-0 flex flex-row md:grid ${getGridColClasses(
        dealsInProgressLoans.length
      )} md:w-full overflow-x-auto overflow-y-hidden md:h-auto gap-4 md:gap-6`}
    >
      {dealsInProgressLoans.map((loan, index) => (
        <LoanSummaryTile
          loan={loan}
          key={`${loan.tempId}-${index}`}
          className={`${
            dealsInProgressLoans.length === 1
              ? 'basis-[94%]'
              : 'basis-[312px] min-w-[312px]'
          } shrink-0 overflow-hidden`}
          isSendMessageToShellApp={true}
          totalLoans={dealsInProgressLoans.length}
          mode="extraNarrow"
          verticalGap={true}
        />
      ))}
      <div className="w-0">&nbsp;</div>
    </div>
  );

  const carouselCards: React.ReactElement[] = [];
  if (dealsInProgressLoans.length > 4) {
    const loanSets: LoanType[][] = [
      dealsInProgressLoans.slice(0, 4),
      dealsInProgressLoans.slice(4),
    ];
    loanSets.forEach((loanSet) => {
      carouselCards.push(
        <div className={`grid grid-cols-2 w-full gap-6`}>
          {loanSet.map((loan, index) => (
            <LoanSummaryTile
              loan={loan}
              key={`${loan.tempId}-${index}`}
              className="min-h-[246px]"
              isSendMessageToShellApp={true}
              mode="extraNarrow"
            />
          ))}
        </div>
      );
    });
  }

  return (
    <div className="md:px-0 overflow-x-auto md:overflow-x-hidden">
      {loansError ? (
        <Alert
          show={true}
          severity="error"
          showClasses={['px-4', 'max-w-xs']}
          message="Loans could not be loaded"
          type="inline"
        />
      ) : loansLoading ? (
        <div className="px-4">
          <LoaderSmall />
        </div>
      ) : dealsInProgressLoans.length > 0 ? (
        <>
          {dealsInProgressLoans.length > 4 ? (
            <>
              <div className="hidden md:block">
                <Carousel
                  cards={carouselCards}
                  slug="loans"
                  heightClass="h-[550px]"
                />
              </div>
              <div className="flex md:hidden">{flatCards}</div>
            </>
          ) : (
            flatCards
          )}
        </>
      ) : (
        <NoActiveDeals />
      )}
    </div>
  );
};

export { DealsInProgress };
