import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { LinkButton, LinkButtonProps } from './LinkButton';

import { ReactComponent as ChevronLeftIcon } from 'Assets/icons/chevron-left-small.svg';

const BackButton = ({
  onClick,
  disabled = false,
  className,
  title,
}: LinkButtonProps) => {
  const navigate = useNavigate();
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (onClick) {
      onClick(event);
    } else {
      navigate(-1);
    }
  };

  return (
    <LinkButton
      onClick={handleButtonClick}
      dataTestId="dt-back-button"
      disabled={disabled}
      className={classNames('flex items-center justify-center', className)}
      title={title}
    >
      <ChevronLeftIcon
        style={{
          filter:
            'invert(38%) sepia(41%) saturate(3673%) hue-rotate(199deg) brightness(90%) contrast(88%)',
        }}
      />
      <div>Back</div>
    </LinkButton>
  );
};

export { BackButton };
