import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  ContactInfo,
  Header1,
  Header2,
  Header3,
  Picture,
  Toast,
} from 'Components';

import type {
  AlertMessage,
  AlertSeverity,
  NodeOrString,
  AgentUser,
  MLSAssociation,
} from 'types';
import {
  capitalCase,
  formatCityStateZip,
  formatPhoneNumber,
  parseUrlForDomain,
} from 'lib/string';
import { inLocalTestModeByEnvVar, matchedObject } from 'lib/util';
import { profile as profileApi } from 'api';

import desktopMonitorIcon from 'Assets/icons/desktop-monitor.svg';
import messageIcon from 'Assets/icons/mail.svg';
import phoneIcon from 'Assets/icons/phone.svg';
import mobilePhoneIcon from 'Assets/icons/mobile-phone.svg';
import locationPinIcon from 'Assets/icons/location-pin-small.svg';
import useCurrentUser from 'contexts/UserContext';
import { useLocation } from 'react-router-dom';

const useTestData = inLocalTestModeByEnvVar('PROFILE_DATA');

const CondensedAgentInfo = (props: {
  term: string;
  value?: string;
  separator?: string;
  isInline?: boolean;
}) => {
  const { term, value, separator = ': ', isInline = false } = props;
  if (value) {
    return (
      <dl
        className={`text-primary-body-base text-sm ${
          isInline ? 'inline-block' : ''
        }`}
        style={{ fontStretch: 'condensed' }}
      >
        <dt className="inline">
          {term}
          {separator}
        </dt>
        <dd className="inline">{value}</dd>
      </dl>
    );
  }
  return <></>;
};

type ProfileAlertMessage = {
  message: NodeOrString;
  uniqueId: string;
};

type AgentProfileType = {
  title: string;
  subTitle: string;
  agent: AgentUser;
  children?: ReactNode;
  initialAlert?: {
    show: boolean;
    severity: AlertSeverity;
    messages: AlertMessage[];
  };
};

const AgentProfile = (props: AgentProfileType) => {
  const { title, subTitle, agent, children, initialAlert } = props;
  const location = useLocation();
  const isProfileUpdated = location.state;
  let {
    firstName,
    lastName,
    loginEmail,
    photoUrl,
    logoUrl,
    brokerageName,
    officePhone,
    mobilePhone,
    website,
    address1,
    address2,
    city,
    state,
    zip,
    mlsAssociationId = '',
    mlsAgentId,
    licenses,
    teamMembers,
  } = agent;

  if (useTestData) {
    website =
      'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/join';
    address1 = '3940 N Ravenswood Ave';
    address2 = 'Unit 100';
    if (!city) city = 'Chicago';
    state = 'Illinois';
    zip = '60613';
    mlsAgentId = '1654878';
    licenses = [
      { state: 'IL', content: '468468468' },
      { state: 'AL', content: '23423423' },
      { state: 'GA', content: '9948034' },
    ];
    teamMembers = [
      {
        firstName: 'Greg',
        lastName: 'Johnson',
        email: 'gregjohnson@berkshirehathawayberkshirehathaway.com',
        title: 'Jr Sales Associate',
        phone: '987-867-5309',
      },
      {
        firstName: 'Barbara',
        lastName: 'Roberts',
        email: 'barbararoberts@berkshirehathaway.com',
        title: 'Jr Sales Associate',
        phone: '987-588-2300',
      },
      {
        firstName: 'Claudine',
        lastName: 'Mikos',
        email: 'claudinemikos@berkshirehathaway.com',
        title: 'Jr Sales Associate',
        phone: '987-243-9985',
      },
    ];
  }

  const { mlsAssociations, setMlaAssociations } = useCurrentUser();
  const [aaMlsAssociation, setAaMlsAssociation] = useState(
    mlsAssociationId ? 'MLS association ID #' + mlsAssociationId : ''
  );
  const [alertMessages, setAlertMessages] = useState<ProfileAlertMessage[]>(
    initialAlert && initialAlert?.messages && initialAlert.messages.length > 0
      ? initialAlert.messages.map((message, index) => ({
          message,
          uniqueId: `profile-alert-message-${index}`,
        }))
      : []
  );
  const [profileEditAlertMessages, setProfileEditAlertMessages] =
    useState<string>('');
  const [photoWarningMessage, setPhotoWarningMessage] =
    useState<NodeOrString>('');
  const [logoWarningMessage, setLogoWarningMessage] =
    useState<NodeOrString>('');
  const [showAlert, setShowAlert] = useState(initialAlert?.show || false);
  const [profileEditShowAlert, setProfileEditShowAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>(
    initialAlert?.severity || 'warning'
  );
  const fixHttpsPrefix = (url: string) => {
    var regex = /^(http|https):\/\//;
    if (!regex.test(url)) {
      url = 'https://' + url;
    }

    return url;
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!mlsAssociations || mlsAssociations.length === 0) {
        const getMlsAssociations =
          async (): Promise<Array<MLSAssociation> | void> => {
            try {
              const { associations } = await profileApi.getMlsAssociations();
              if (associations?.length) {
                setMlaAssociations(associations);
              }
            } catch (error) {}
          };

        getMlsAssociations();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [mlsAssociations, setMlaAssociations]);

  useEffect(() => {
    if (mlsAssociations && mlsAssociations.length > 0 && mlsAssociationId) {
      const matchedAssociation = matchedObject(
        mlsAssociations,
        'id',
        mlsAssociationId.toString()
      );
      if (typeof matchedAssociation === 'object') {
        setAaMlsAssociation(matchedAssociation.name);
      }
    }
  }, [mlsAssociationId, mlsAssociations, setAaMlsAssociation]);

  useEffect(() => {
    let messages: ProfileAlertMessage[] = [];
    let severity: AlertSeverity = initialAlert?.severity || 'warning';
    if (
      initialAlert &&
      initialAlert?.messages &&
      initialAlert.messages.length > 0
    ) {
      messages = initialAlert.messages.map((message, index) => ({
        message,
        uniqueId: `profile-alert-message-${index}`,
      }));
    }
    if (photoWarningMessage) {
      messages.push({ message: photoWarningMessage, uniqueId: 'photo' });
    }
    if (logoWarningMessage) {
      messages.push({ message: logoWarningMessage, uniqueId: 'logo' });
    }
    setAlertMessages(messages);
    setAlertSeverity(severity);
    setShowAlert(messages.length > 0);
    if (isProfileUpdated) {
      setProfileEditAlertMessages(isProfileUpdated);
      setProfileEditShowAlert(true);
    }
    return () => {
      window.history.replaceState({}, '');
    };
  }, [
    initialAlert,
    photoWarningMessage,
    logoWarningMessage,
    setAlertMessages,
    setAlertSeverity,
    isProfileUpdated,
  ]);

  const AlertMessage = useMemo(() => {
    if (alertMessages.length > 0) {
      return (
        <Alert
          type="banner"
          show={showAlert}
          severity={alertSeverity}
          showClasses={['flex-grow']}
          message={
            alertMessages.length === 1 ? (
              alertMessages[0].message
            ) : (
              <div className="ml-3">
                {alertMessages.map((message) => (
                  <div
                    className="list-disc whitespace-normal"
                    key={message.uniqueId}
                  >
                    {message.message}
                  </div>
                ))}
              </div>
            )
          }
        />
      );
    }
    return <></>;
  }, [alertMessages, alertSeverity, showAlert]);
  const h3Classes = 'text-xl text-primary-body-base font-thin m-0';

  return (
    <div className="w-full flex flex-col gap-5" data-testid="agent-profile">
      <Header1 classes={['my-0']}>{title}</Header1>
      {subTitle && <p className="text-primary-body-base">{subTitle}</p>}
      {alertMessages.length > 0 && (
        <div className="hidden md:block">{AlertMessage}</div>
      )}
      {profileEditAlertMessages.length > 0 && (
        <Toast
          open={profileEditShowAlert}
          severity={'success'}
          message={profileEditAlertMessages}
          onClose={() => {
            setProfileEditShowAlert(false);
          }}
        />
      )}
      <section className="flex flex-col md:flex-row md:justify-between gap-4 md:gap-10 overflow-hidden">
        <div className="flex flex-col justify-start w-full md:grow-0 md:shrink-0 md:w-[192px] gap-4">
          <Picture
            size={20}
            src={photoUrl}
            alt={`${firstName} ${lastName} headshot`}
            descriptor="Headshot"
            variant={'roundedRectangular'}
            validate={true}
            setValidationMessage={setPhotoWarningMessage}
          />
          <Picture
            src={logoUrl}
            alt={`${brokerageName || ''} branding image`}
            descriptor="Branding image"
            variant={'rectangular'}
            validate={true}
            setValidationMessage={setLogoWarningMessage}
          />
        </div>
        {alertMessages.length > 0 && (
          <div className="block md:hidden">{AlertMessage}</div>
        )}
        <div className="md:w-[678px] grow-0 flex flex-col gap-2">
          <Header2 classes={['m-0']}>
            {firstName} {lastName}
          </Header2>
          {(agent?.title || agent?.brokerageName) && (
            <div>
              {agent?.title && <h3 className={h3Classes}>{agent.title}</h3>}
              {agent?.brokerageName && (
                <h3 className={h3Classes}>{agent.brokerageName}</h3>
              )}
            </div>
          )}
          <div className="md:columns-2 md:gap-x-6 w-full">
            {loginEmail && (
              <ContactInfo src={messageIcon} alt="email" iconSize={5}>
                <Button externalHref={`mailto:${loginEmail}`} type="quaternary">
                  {loginEmail}
                </Button>
              </ContactInfo>
            )}
            {mobilePhone && (
              <ContactInfo
                src={mobilePhoneIcon}
                alt="phone"
                iconSize={5}
                applySizeToIcon={false}
              >
                <Button externalHref={`tel:${mobilePhone}`} type="quaternary">
                  {formatPhoneNumber(mobilePhone)}
                </Button>
              </ContactInfo>
            )}
            {officePhone && (
              <ContactInfo src={phoneIcon} alt="phone" iconSize={5}>
                <Button externalHref={`tel:${officePhone}`} type="quaternary">
                  {formatPhoneNumber(officePhone)}
                </Button>
              </ContactInfo>
            )}
            {website && website !== '' && (
              <ContactInfo src={desktopMonitorIcon} alt="phone" iconSize={5}>
                <Button
                  externalHref={fixHttpsPrefix(website)}
                  type="quaternary"
                >
                  {parseUrlForDomain(website)}
                </Button>
              </ContactInfo>
            )}
            {(address1 || address2 || city || state || zip) && (
              <ContactInfo
                src={locationPinIcon}
                alt="Address"
                iconSize={5}
                applySizeToIcon={false}
              >
                <div className="leading-6">
                  {address1 && (
                    <p className="font-bold text-primary-body-base text-ellipsis leading-6 m-0">
                      {address1}
                    </p>
                  )}
                  {address2 && (
                    <p className="font-bold text-primary-body-base text-ellipsis leading-6 m-0">
                      {address2}
                    </p>
                  )}
                  {(city || state || zip) && (
                    <p className="font-bold text-primary-body-base text-wrap break-all leading-6">
                      {formatCityStateZip(capitalCase(city || ''), state, zip)}
                    </p>
                  )}
                </div>
              </ContactInfo>
            )}
          </div>
          <div>
            <CondensedAgentInfo
              term="MLS association"
              value={aaMlsAssociation}
            />
            <CondensedAgentInfo term="MLS agent ID" value={mlsAgentId} />
          </div>
          {licenses && licenses.length > 0 && (
            <div className="inline">
              {licenses?.map((stateLicense, index) => (
                <span key={`state-license-${index}`}>
                  {index > 0 && <span>, </span>}
                  <CondensedAgentInfo
                    term={stateLicense.state}
                    value={stateLicense.content}
                    separator=" - "
                    isInline={true}
                  />
                </span>
              ))}
            </div>
          )}
          {(!teamMembers || teamMembers.length === 0) && children}
        </div>
      </section>
      {teamMembers && teamMembers.length > 0 && (
        <>
          <hr className="m-0 h-0 flex-1 border-t border-b-0 border-secondary-inactive-lighter w-full" />
          <section className="md:flex md:flex-row md:justify-end mt-1">
            <div className="md:w-[678px] grow-0 flex flex-col gap-4">
              <Header3 classes={['my-0']}>My team</Header3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
                {teamMembers.map((teamMember, index) => (
                  <div
                    className="flex flex-col gap-2 break-inside-avoid-column"
                    key={`team-member-${index}`}
                  >
                    <div>
                      <h4 className="text-primary-body-base font-bold text-base -mb-1">
                        {teamMember.firstName} {teamMember.lastName}
                      </h4>
                      {teamMember?.title && (
                        <p className="text-primary-body-base text-base -mb-0.5">
                          {teamMember.title}
                        </p>
                      )}
                    </div>
                    <div className="-mt-2">
                      {teamMember?.email && (
                        <ContactInfo src={messageIcon} alt="email" iconSize={5}>
                          <Button
                            externalHref={`mailto:${teamMember.email}`}
                            type="quaternary"
                          >
                            {teamMember.email}
                          </Button>
                        </ContactInfo>
                      )}
                      {teamMember?.phone && (
                        <ContactInfo src={phoneIcon} alt="phone" iconSize={5}>
                          <Button
                            externalHref={`tel:${teamMember.phone}`}
                            type="quaternary"
                          >
                            {formatPhoneNumber(teamMember.phone)}
                          </Button>
                        </ContactInfo>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {children}
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export { AgentProfile };
