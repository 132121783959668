import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import UserContext from 'contexts/UserContext';
import { StyledMenu } from 'Components';

export function SubNavigationMenu(props: React.PropsWithChildren<{}>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user } = UserContext();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path: string) => {
    handleClose();
    navigate(path);
  };

  return (
    <div>
      <Button
        id="subNavigationButton"
        aria-controls={open ? 'subNavigationMenu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        style={{ backgroundColor: 'transparent', minWidth: 0, padding: 0 }}
        disableRipple
      >
        {props.children}
      </Button>
      <StyledMenu
        id="subNavigationMenu"
        MenuListProps={{
          'aria-labelledby': 'subNavigationButton',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => handleMenuItemClick('/professional-profile')}
          disableRipple
        >
          Professional Profile
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick('/change-password')}
          disableRipple
        >
          Update password
        </MenuItem>
        {/* <MenuItem
          onClick={() => handleMenuItemClick('/change-email')}
          disableRipple
        >
          Update email
        </MenuItem> */}
        <MenuItem onClick={() => handleMenuItemClick('/help')} disableRipple>
          How are we doing?
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => handleMenuItemClick('/logging-out')}
          disableRipple
        >
          {user?.loginEmail ? `Log out of ${user?.loginEmail}` : 'Log out'}
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
