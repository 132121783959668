import classNames from 'classnames';
import styles from './Checkbox.module.css';

type CheckboxProps = {
  id?: string;
  name?: string;
  label?: string;
  checked: boolean;
  onChange: (e: any) => void;
  'data-testid'?: string;
  dataTestId?: string;
  classnames?: string[];
  disabled?: boolean;
};

const Checkbox = (props: CheckboxProps) => {
  const attributes = { ...props };
  if (attributes?.dataTestId) {
    attributes['data-testid'] = attributes.dataTestId;
    delete attributes.dataTestId;
  }
  if (attributes?.label) {
    delete attributes.label;
  }
  return (
    <label
      className={classNames([
        'inline-flex',
        'relative',
        'cursor-pointer',
        'text-primary-body-base',
        styles.checkbox,
        attributes?.classnames ? attributes.classnames : '',
      ])}
    >
      <input
        className="appearance-none rounded-s border border-secondary-input-medium bg-white checked:bg-secondary-input-lightest checked:border-secondary-input-base focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain shrink-0 disabled:border-secondary-inactive-lighter peer"
        type="checkbox"
        {...attributes}
      />
      <span
        className={classNames([
          'pl-3',
          attributes?.classnames ? attributes.classnames : '',
          'peer-disabled:text-secondary-inactive-light',
        ])}
      >
        {props?.label}
      </span>
    </label>
  );
};

export { Checkbox };
