import { FC } from 'react';

import useAppContext from 'contexts/AppContext';

export const PageBody: FC = (props) => {
  const { mobileNavIsOpen } = useAppContext();
  return (
    <div
      className={`${mobileNavIsOpen ? 'hidden' : ''} text-primary-body-base`}
    >
      {props.children}
    </div>
  );
};
