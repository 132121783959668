import useCurrentUser from 'contexts/UserContext';

import type { AgentUserStats } from 'types';

import { inLocalTestModeByEnvVar, numberFormatOptions } from 'lib/util';
import {
  showAverageCtc,
  totalLoans,
  closingsSingularOrPlural,
  testStats,
  zeroStats,
} from './util';

import { ReactComponent as ChevronRightArrow } from 'Assets/icons/arrow-chevron-right-small.svg';

const useTestData = inLocalTestModeByEnvVar('CLOSING_STATS');

type LoanProgressBarProps = {
  totalLoans: number;
  completedLoansCount: number;
};
const LoansProgressBar = ({
  totalLoans,
  completedLoansCount,
}: LoanProgressBarProps) => {
  const flexBasis =
    totalLoans > 0
      ? `${((totalLoans - completedLoansCount) / totalLoans) * 100}%`
      : '100%';
  return (
    <div className="bg-status-ok-base h-2 md:h-1 w-full rounded-lg md:rounded flex flex-row justify-end">
      <div
        className="bg-secondary-inactive-lighter rounded-lg"
        style={{ flexBasis }}
      />
    </div>
  );
};

const ClosingStats = () => {
  const { loansError, loansLoading, loanStats } = useCurrentUser();
  const stats: AgentUserStats = useTestData
    ? testStats
    : loanStats || zeroStats;
  const showAvgCtc = showAverageCtc(stats);
  return (
    <div
      data-testid="agent-closing-stats"
      className={loansError || loansLoading ? 'hidden' : ''}
    >
      <div className={`flex flex-col gap-2 ${showAvgCtc ? '' : 'mt-3'} mb-1`}>
        {showAvgCtc && (
          <h4
            className="mb-0 text-primary-body-base font-bold text-base md:text-lg flex flex-row items-center gap-2 md:justify-end md:mb-1"
            data-testid="agent-average-ctc"
          >
            <ChevronRightArrow className="hidden md:block" />
            Average CTC: {stats.rollingYear.averageCtc} days
          </h4>
        )}
        <LoansProgressBar
          totalLoans={totalLoans(stats)}
          completedLoansCount={stats.monthToDate.funded.loansCount}
        />
        <div className="flex flex-row justify-between">
          <div className="flex flex-col md:flex-row gap-0 md:gap-2">
            <p
              className="mb-0 text-primary-body-base"
              data-testid="agent-mtd-funded-count"
            >
              {stats.monthToDate.funded.loansCount} completed{' '}
              {closingsSingularOrPlural(stats.monthToDate.funded.loansCount)}
            </p>
            <p
              className="mb-0 text-primary-body-base font-bold"
              data-testid="agent-mtd-funded-volume"
            >
              {new Intl.NumberFormat('en-US', numberFormatOptions).format(
                stats.monthToDate.funded.salesVolume
              )}
            </p>
          </div>
          <div className="text-right flex flex-col md:flex-row gap-0 md:gap-2">
            <p
              className="mb-0 text-primary-body-base"
              data-testid="agent-mtd-projected-count"
            >
              {stats.monthToDate.projectedClosed.loansCount} projected{' '}
              {closingsSingularOrPlural(
                stats.monthToDate.projectedClosed.loansCount
              )}
            </p>
            <p
              className="mb-0 text-primary-body-base font-bold"
              data-testid="agent-mtd-projected-volume"
            >
              {new Intl.NumberFormat('en-US', numberFormatOptions).format(
                stats.monthToDate.projectedClosed.salesVolume
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ClosingStats };
