import { Button, Dialog, Header3 } from 'Components';

interface InvalidTokenModalProps {
  open: boolean;
  onClose: () => void;
}

export const InvalidTokenModal = ({
  open,
  onClose,
}: InvalidTokenModalProps) => (
  <Dialog
    open={open}
    onClose={(_evt, reason) => reason !== 'backdropClick' && onClose()}
  >
    <div className="px-8 pb-8 md:w-[32rem]">
      <Header3 classes={['mb-4', 'md:text-xl']}>
        Invalid verification link
      </Header3>
      <p className="text-base text-primary-body-base">
        Looks like this verification link isn't valid.
        <br />
        Please{' '}
        <Button
          type="quaternary"
          className={['underline']}
          to="/create-account"
        >
          click here
        </Button>{' '}
        to send a new one.
      </p>
    </div>
  </Dialog>
);
