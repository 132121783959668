import { NodeOrString, PropertyType } from 'types';
import {
  capitalCase,
  formatCityStateZip,
  includesCaseInsensitive,
} from 'lib/string';

const Address = (props: {
  emptyString: NodeOrString;
  property?: PropertyType;
}) => {
  const { emptyString, property } = props;

  if (property === undefined || (!!!property?.address && !!!property?.state)) {
    return <>{emptyString}</>;
  }

  const allPropertyValuesEmpty = Object.values(property).every(
    (propertyField) =>
      propertyField === undefined ||
      propertyField === null ||
      propertyField === ''
  );
  if (
    allPropertyValuesEmpty ||
    (property.address && includesCaseInsensitive(property.address, 'PREQUAL'))
  ) {
    return <>{emptyString}</>;
  }

  const address = capitalCase(property?.address || '');
  return (
    <div className="leading-5">
      {address && (
        <p className="block md:inline m-0">
          {address}
          <span className="hidden md:inline">, </span>
        </p>
      )}
      <p className="block md:inline m-0">
        {formatCityStateZip(
          capitalCase(property.city || ''),
          property.state,
          property.zip
        )}
      </p>
    </div>
  );
};

export { Address };
