import { FC, Children, cloneElement } from 'react';
import classNames from 'classnames';
import styles from './Section.module.css';
import { SectionProps } from 'types';

function hasMultipleChildren(
  children: JSX.Element | JSX.Element[]
): children is JSX.Element[] {
  return Array.isArray(children);
}

export const Section: FC<SectionProps> = (props) => {
  const { children, className, contentProps = {} } = props;
  if (!children) {
    return null;
  }
  const styledChildren = () => {
    const cloneChildWithStyle = (child: JSX.Element) =>
      cloneElement(child, {
        className: `desktop:mb-8 ${styles.content} ${
          child.props.className || ''
        }`,
      });

    if (hasMultipleChildren(children)) {
      return Children.map(children, cloneChildWithStyle);
    }
    return cloneChildWithStyle(children);
  };

  const { className: contentClassName, ...otherContentProps } = contentProps;

  const outerClasses = ['flex', 'justify-center', 'items-center'];
  const innerClasses = ['flex', 'flex-col', 'desktop:flex-row'];

  let outerClassname = classNames(outerClasses, className);
  let innerClassname = classNames(innerClasses, contentClassName);
  if (props.addMargin) {
    outerClassname = classNames(
      outerClasses,
      'py-8',
      'desktop:py-16',
      'tablet:py-12',
      className
    );
    innerClassname = classNames(
      innerClasses,
      'mx-6',
      'md:mx-0',
      'md:w-2/3',
      'pt-2',
      'desktop:max-w-screen-desktop',
      contentClassName
    );
  }

  return (
    <div className={outerClassname}>
      <div className={innerClassname} {...otherContentProps}>
        {styledChildren()}
      </div>
    </div>
  );
};
Section.defaultProps = {
  addMargin: true,
};
