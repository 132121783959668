const linkClasses = [
  'py-5',
  'px-1',
  'md:px-3',
  'lg:px-4',
  'space-x-2',
  'text-base',
  'font-bold',
  'text-primary-body-base',
  'whitespace-nowrap',
  'hover:underline',
  'hover:underline-offset-8',
];
const activeSideNavClasses = ['underline', 'underline-offset-8'];

export { linkClasses, activeSideNavClasses };
