import classNames from 'classnames';
import { formatPhoneNumber } from 'lib/string';
import useWindowDimensions from 'lib/hooks/useWindowDimensions';

import messageIcon from 'Assets/icons/mail.svg';
import phoneIcon from 'Assets/icons/phone.svg';

export interface ContactLineProps {
  email?: string | null;
  phone?: string | null;
  mode?: 'narrow' | 'extraNarrow' | 'wide';
}

const ContactLine = ({ email, phone, mode = 'narrow' }: ContactLineProps) => {
  const { isMobileScreenWidth, width } = useWindowDimensions();

  const tertiary = [`text-primary-body-base`, 'font-normal'];

  const calcMode = width < 415 ? 'extraNarrow' : mode;

  const layout: 'oneLine' | 'twoLines' =
    email &&
    (calcMode === 'extraNarrow' ||
      (email.length > 50 && mode === 'narrow') ||
      (email.length > 80 && mode === 'wide'))
      ? 'twoLines'
      : 'oneLine';

  const className = classNames(tertiary);

  return (
    <div
      className={classNames('flex', {
        'flex-row gap-3': layout === 'oneLine',
        'flex-col': layout === 'twoLines',
      })}
    >
      {email && (
        <div
          className={classNames('flex items-start', {
            'leading-5': layout === 'twoLines',
            'text-sm':
              mode === 'extraNarrow' ||
              (layout === 'twoLines' && mode === 'narrow'),
          })}
        >
          <img className="w-4 h-4 mr-1 mt-1" src={messageIcon} alt="email" />
          <div
            className={classNames(className, {
              truncate: !isMobileScreenWidth,
              'break-all': isMobileScreenWidth,
            })}
          >
            {email}
          </div>
        </div>
      )}
      {phone && (
        <div className="flex items-center">
          <img className="w-4 h-4 mr-1" src={phoneIcon} alt="phone" />
          <div
            className={classNames(className, {
              'leading-5 text-sm': mode === 'extraNarrow',
            })}
          >
            {formatPhoneNumber(phone)}
          </div>
        </div>
      )}
    </div>
  );
};

export { ContactLine };
