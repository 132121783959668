import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import useAppContext from 'contexts/AppContext';
import useCurrentUser from 'contexts/UserContext';
import {
  AuthMainNav,
  AuthMobileWebMenu,
  SubNavigationMenu,
  HiUser,
  MobileNavToggle,
  ReferButton,
} from 'Components';

import { RateAgentsLogo } from '../NavBar/RateAgentsLogo';
import { ReactComponent as AvatarPlaceholder } from 'Assets/icons/avatar-placeholder-small.svg';

import styles from './LoginHeader.module.css';

const LoginHeader = () => {
  const { isRunningInShellApp, setMobileNavIsOpen } = useAppContext();
  const { user } = useCurrentUser();
  const userInfo = user || { photoUrl: '', firstName: '', lastName: '' };
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headerClassname = useMemo(() => {
    let className = 'sticky top-0 z-50 h-17 bg-white w-full pr-4 md:pl-4';
    className += scrolled ? ' drop-shadow-md' : '';
    return className;
  }, [scrolled]);

  const profileCircleClassNames =
    'rounded-full object-cover h-6 md:h-9 w-6 md:w-9 max-w-none bg-gradient-to-b from-gray-100 to-gray-200 border-0';

  const placeholderAvatarClassNames = 'h-6 md:h-9 w-6 md:w-9 rounded-full';

  const Avatar =
    userInfo && userInfo.photoUrl ? (
      <img
        src={userInfo.photoUrl}
        className={profileCircleClassNames}
        alt="profile"
      />
    ) : (
      <AvatarPlaceholder className={placeholderAvatarClassNames} />
    );
  return isRunningInShellApp ? (
    <></>
  ) : (
    <>
      <nav
        role="navigation"
        aria-label="navigation"
        className={headerClassname}
      >
        <div className="w-full fullhd:max-w-1440px h-full flex items-center justify-between fullhd:mx-auto">
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center">
              {!isRunningInShellApp && <MobileNavToggle />}
              <Link
                to="/dashboard"
                className={`${styles.logoLink} ${
                  isRunningInShellApp ? 'ml-4' : ''
                } flex items-center h-17 mb-1 md:relative md:relative md:top-0 md:h-auto md:mr-4 text-primary-body-base focus:text-primary-body-base hover:text-primary-body-base active:text-primary-body-base`}
                onClick={() => setMobileNavIsOpen(false)}
              >
                <RateAgentsLogo auth={true} />
              </Link>
              <AuthMainNav />
            </div>
            <div className="flex items-center gap-2 md:hidden">
              <Link
                to="/professional-profile"
                onClick={() => setMobileNavIsOpen(false)}
              >
                {Avatar}
              </Link>
            </div>
          </div>
          <div className="hidden md:flex md:items-center md:gap-2 lg:gap-4 xl:gap-8">
            <ReferButton size="small" />
            <div className="flex md:items-center gap-4">
              {user?.firstName && user?.firstName?.length <= 30 && (
                <Link
                  to="/professional-profile"
                  className="md:hidden lg:block text-sm text-primary-body-base hover:text-primary-body-dark hover:underline active:scale-90 whitespace-nowrap"
                >
                  <HiUser size={4} />
                </Link>
              )}
              <SubNavigationMenu>{Avatar}</SubNavigationMenu>
            </div>
          </div>
        </div>
      </nav>
      <AuthMobileWebMenu />
    </>
  );
};

export { LoginHeader };
