import { ReactNode } from 'react';
import classNames from 'classnames';

const Header1 = (props: { children: ReactNode; classes?: string[] }) => (
  <h1
    className={classNames(
      'font-bold',
      'text-3xl',
      'md:text-5xl',
      'text-primary-body-base',
      'tracking-normal',
      ...(props.classes ?? [])
    )}
  >
    {props.children}
  </h1>
);

export { Header1 };
