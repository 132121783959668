import { FormikProps } from 'formik';

import { AgentUser, ValidationMessage } from 'types';

const passwordValidation: { [key: string]: ValidationMessage } = {
  minchar: {
    title: 'At least 8 characters',
    error: 'Must have at 8 characters',
  },
  lowercase: {
    title: 'A lowercase letter',
    error: 'Must have at least one lowercase character',
  },
  uppercase: {
    title: 'An uppercase letter',
    error: 'Must have at least one uppercase character',
  },
  number: {
    title: 'A number',
    error: 'Must have at least one number',
  },
  symbol: {
    title: 'A symbol',
    error: 'Must have at least one symbol',
  },
  email: {
    title: 'No parts of your email address',
    error: 'Cannot contain any part of your email',
  },
  firstName: {
    title: 'Does not include your first name',
    error: 'Cannot contain any part of your first name',
  },
  lastName: {
    title: 'Does not include your last name',
    error: 'Cannot contain any part of your last name',
  },
  recent: {
    title: 'Cannot be any of your last 5 passwords',
    error: 'Cannot be any of your last 5 passwords',
  },
};

const messages = {
  confirmation: 'Passwords must match',
  required: 'Required',
  emailFormat: 'Please enter a valid email address.',
  title: 'Title is required',
  stateLicense:
    'The license number will appear in the required places of the marketing materials you create.',
  max: (max: number) => `Max ${max} characters`,
  min: (min: number) => `Must be at least ${min} characters`,
  phone: 'Please enter a valid 10-digit phone number',
  password: passwordValidation,
  website: 'Website should be a valid url',
  stateForLicense:
    'We need to know which state your License Number belongs to - please select your state from the dropdown.',
  licenseForState: 'Please enter the state you are licensed in.',
  zipCode: 'Zip code must match the ZIP+4 format.',
  clientEmailReq: 'Enter client email',
  clientPhoneReq: 'Enter client phone',
  listingAddress2: 'Enter listing address 2',
  brokerage: 'Brokerage is required',
};

const referralSchema: {
  [key: string]: { [key: string]: any };
} = {
  clientFirstName: {
    max: 50,
  },
  clientLastName: {
    max: 50,
  },
  clientPhone: {
    max: 20,
  },
};
const schema: { [key: string | keyof AgentUser]: { [key: string]: any } } = {
  firstName: {
    max: 50,
  },
  lastName: {
    max: 50,
  },
  title: {
    max: 50,
  },
  officePhone: {
    max: 20,
  },
  mobilePhone: {
    max: 20,
  },
  email: {
    max: 100,
  },
  brokerageName: {
    max: 100,
  },
  address1: {
    max: 100,
  },
  address2: {
    max: 100,
  },
  city: {
    max: 100,
  },
  state: {
    max: 2,
  },
  zip: {
    max: 10,
  },
  mlsAgentId: {
    max: 255,
  },
  stateLicense: {
    max: 250,
  },
};

function isBlank(str: string = ''): boolean {
  return str.trim().length === 0;
}

// urlRegex from https://gist.github.com/dperini/729294 with one addition to make the protocol/scheme optional

const regex = {
  atLeastOneLower: /^(?=.*[a-z]).*$/,
  atLeastOneUpper: /^(?=.*[A-Z]).*$/,
  atLeastOneNumber: /\d/,
  atLeastOneSymbol: /^(?=.*[!@#$%^’`&()*+,./\\:;<=>?[\]_{}|~]).*$/,
  email: /^[A-Za-z0-9+_.-]+@([A-Za-z0-9-]+\.)[A-Za-z]{2,6}$/,
  phone: /^(?:\([2-9]\d{2}\)\ ?|[2-9]\d{2}(?:\-?|\ ?))[2-9]\d{2}[- ]?\d{4}$/,
  website: new RegExp(
    '^' +
      // protocol identifier (optional)
      // short syntax // still required
      '(?:(?:(?:https?|ftp):)?\\/\\/)?' +
      // user:pass BasicAuth (optional)
      '(?:\\S+(?::\\S*)?@)?' +
      '(?:' +
      // IP address exclusion
      // private & local networks
      '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
      '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
      '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broadcast addresses
      // (first & last IP address of each class)
      '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
      '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
      '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
      '|' +
      // host & domain names, may end with dot
      // can be replaced by a shortest alternative
      // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
      '(?:' +
      '(?:' +
      '[a-z0-9\\u00a1-\\uffff]' +
      '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
      ')?' +
      '[a-z0-9\\u00a1-\\uffff]\\.' +
      ')+' +
      // TLD identifier name, may end with dot
      '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
      ')' +
      // port number (optional)
      '(?::\\d{2,5})?' +
      // resource path (optional)
      '(?:[/?#]\\S*)?' +
      '$',
    'i'
  ),
  zipCode: /^[0-9]{5}(?:-[0-9]{4})?$/,
};

const isEmail = (str: string = ''): boolean => {
  const email = str.trim();
  if (!email) return false;
  return regex.email.test(email);
};

const isZipCode = (str: string = ''): boolean => regex.zipCode.test(str.trim());

/*
  Verifies that no N-character substring of the provided query text ("needle") is part of the text to be searched ("haystack").
  Modelled after the Rate App's password verification function
  https://github.com/Guaranteed-Rate/SuperApp-iOS/blob/develop/SuperApp/Features/Entry/SignIn/Validators/CreateAccountValidator.swift#L108-L126
*/
const stringContainsSubstringOf = (
  haystack: string = '', // the search text
  needle: string = '', //
  minLength: number = 4 // minimum length of substring to consider
): boolean => {
  // Convert both strings to lowercase
  const haystackLowerCase = haystack.toLowerCase();
  const needleLowerCase = needle.toLowerCase();

  // Loop through all possible substrings of needle with length minLength or greater
  for (let i = 0; i < needleLowerCase.length - minLength + 1; i++) {
    const substring = needleLowerCase.slice(i, i + minLength);
    if (haystackLowerCase.includes(substring)) {
      return true;
    }
  }

  return false;
};

const extraPasswordValidation = (
  formik: FormikProps<any>,
  password: string = '',
  firstName: string = '',
  lastName: string = '',
  email: string = ''
) => {
  if (password) {
    let hasFirstName = false;
    let hadExtraError = false;
    if (firstName) {
      if (stringContainsSubstringOf(password, firstName)) {
        hasFirstName = true;
        formik.setStatus({
          ...formik.status,
          extraPasswordError: passwordValidation.firstName.error,
        });
        hadExtraError = true;
      }
    }
    if (!hasFirstName && lastName) {
      if (stringContainsSubstringOf(password, lastName)) {
        formik.setStatus({
          ...formik.status,
          extraPasswordError: passwordValidation.lastName.error,
        });
        hadExtraError = true;
      }
    }
    if (email) {
      if (stringContainsSubstringOf(password, email)) {
        formik.setStatus({
          ...formik.status,
          extraPasswordError: passwordValidation.email.error,
        });
        hadExtraError = true;
      }
    }
    if (!hadExtraError) {
      formik.setStatus({ ...formik.status, ...{ extraPasswordError: '' } });
    }
  }
};

// returns a string detailing marketing profile errors, or an empty string if no errors
const requiredMarketingProfileErrors = (agentUser: AgentUser): string => {
  let messages = [];
  !agentUser.firstName && messages.push('first name');
  !agentUser.lastName && messages.push('last name');
  if (!messages.length) {
    return '';
  }

  return (
    'Your profile is incomplete. Provide ' +
    messages.join(', ').replace(/, ([^,]*)$/, ' and $1') +
    '.'
  );
};
export {
  stringContainsSubstringOf,
  extraPasswordValidation,
  isBlank,
  isEmail,
  isZipCode,
  messages,
  passwordValidation,
  regex,
  schema,
  referralSchema,
  requiredMarketingProfileErrors,
};
