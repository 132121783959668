import { Link } from 'react-router-dom';
import type { OpenHouse } from 'types';
import { capitalCase, formatCityStateZip } from 'lib/string';
import { OpenHouseContextMenu } from './ContextMenu';
import { useWindowDimensions } from 'lib/hooks';
import { tabletDimension } from 'Components/Common/Utils';

import { ReactComponent as EllipsisVertical } from 'Assets/icons/ellipsis-vertical.svg';
import { ReactComponent as ChevronRight } from 'Assets/Images/chevron-right-1.svg';

const OpenHouseRow = (props: { openHouse: OpenHouse }) => {
  const { openHouse } = props;
  const { id = '', address } = openHouse;
  const { width } = useWindowDimensions();
  const isMobile = width && width <= tabletDimension;

  const line1 = capitalCase(address?.line1 || '');
  const Property = () => (
    <div className="text-primary-body-base hover:text-primary-body-base active:text-primary-body-base leading-6">
      {line1 && (
        <p className="block md:inline m-0">
          {line1}
          <span className="hidden md:inline">, </span>
        </p>
      )}
      {address?.line2 && (
        <p className="block md:inline m-0">
          {address?.line2}
          <span className="hidden md:inline">, </span>
        </p>
      )}
      <p className="block md:inline m-0">
        {formatCityStateZip(
          capitalCase(address.city || ''),
          address.state,
          address.zip
        )}
      </p>
    </div>
  );
  return isMobile ? (
    <div className="border-t border-secondary-inactive-lighter px-2 py-2 flex flex-row items-center justify-between  md:hidden odd:bg-white even:bg-secondary-action-hover">
      <Property />
      <OpenHouseContextMenu id={id}>
        <EllipsisVertical />
      </OpenHouseContextMenu>
    </div>
  ) : (
    <Link
      className="hidden md:flex py-2 pl-3 pr-2 border border-secondary-inactive-lighter rounded-xl bg-white flex-row items-center justify-between hover:bg-secondary-action-hover"
      to={`/open-house/${openHouse.id}`}
    >
      <Property />
      <div className="w-7 h-7 flex justify-center items-center">
        <ChevronRight />
      </div>
    </Link>
  );
};

export { OpenHouseRow };
