import { ReactNode } from 'react';
import { Header, Footer } from 'Components';
import { PageBody } from 'Components/Page/Components';

export function Shell({ children }: { children: ReactNode }) {
  return (
    <>
      <Header />
      <PageBody>
        <div className="flex justify-center p-3 w-full mb-40 md:mt-12 text-primary-body-base">
          {children}
        </div>
      </PageBody>
      <Footer />
    </>
  );
}
