import { useMemo } from 'react';
import { get } from 'lodash';
import classNames from 'classnames';

import { Address, CircleAvatar } from 'Components';
import { LoanStatusDates, LoanType } from 'types';

import { capitalCase } from 'lib/string';
import {
  formatBorrowerDisplayName,
  getFormattedShortStage,
  isReferralConsentNotDenied,
  loanIsInProgress,
} from 'lib/loans';

import { numberFormatOptions } from 'lib/util';
import { getLatest, humanizeDateTime } from 'lib/date';
import { BorrowerConsentAlert } from 'Components/BorrowerConsentAlert/BorrowerConsentAlert';
import type { ShellAppHandleLoanDetailMessage } from 'lib/shell-app';
import { useNavigate } from 'react-router-dom';
import useAppContext from 'contexts/AppContext';
import { sendMessageToShellApp } from 'lib/shell-app';

import { ContactLine } from './ContactLine';

import { ReactComponent as ChevronRight } from 'Assets/Images/chevron-right-1.svg';

type MiniLoanOfficer = {
  displayName: string;
  photoUrl: string;
};

const formatUpdatedBlurb = (
  loanStatusDates: LoanStatusDates,
  isInprogress: boolean
): string => {
  const latestDate = getLatest(loanStatusDates, isInprogress);
  if (latestDate) {
    return (
      (isInprogress ? 'Updated ' : 'Closed on ') + humanizeDateTime(latestDate)
    );
  }
  return '';
};

type LoanSummaryTileProps = {
  loan: LoanType;
  active?: boolean;
  mobileFullWidth?: boolean;
  className?: string;
  isSendMessageToShellApp?: boolean;
  totalLoans?: number;
  mode?: 'narrow' | 'extraNarrow' | 'wide';
  verticalGap?: boolean;
};

const LoanSummaryTile = ({
  loan,
  active = true,
  mobileFullWidth = false,
  className = '',
  isSendMessageToShellApp,
  mode = 'narrow',
  verticalGap = false,
}: LoanSummaryTileProps) => {
  const {
    borrower,
    coborrower,
    loanStatusDates = {},
    status,
    property,
    team,
    consent,
  } = loan;

  const guid = loan?.loanDetails?.grLoanGuid || 'unknown';
  let pathname = '/closings/' + guid;
  const closingsMessage: ShellAppHandleLoanDetailMessage = {
    type: 'OPEN_AGENTS_CLOSINGS_LOAN_DETAIL',
    payload: guid,
  };
  const fundedDate = get(loan, 'loanStatusDates.funded');
  const isFunded = !!fundedDate;

  const { isRunningInShellApp, setIsPrevUrlDashboard } = useAppContext();
  const navigate = useNavigate();

  const lastCompletedStageFormatted = useMemo(() => {
    return getFormattedShortStage(loan, status?.lastCompletedStage || '');
  }, [status, loan]);

  const getDisposition = (loan: LoanType): string =>
    loan?.status?.currentMilestone === 'referral' && loan?.disposition?.current
      ? loan.disposition.current
      : '';

  const dispositionStatusFormatted = useMemo(
    () => getDisposition(loan),
    [loan]
  );

  const isInProgress = useMemo(() => loanIsInProgress(loan), [loan]);

  const consentValue =
    consent?.borrower === undefined ? null : consent.borrower;

  const showFullDetails = isFunded || consentValue === 1;

  const isOpenReferral = isReferralConsentNotDenied(loan);

  const isFullOrOpenReferral = showFullDetails || isOpenReferral;

  const loanOfficer: MiniLoanOfficer = useMemo(
    () =>
      isFullOrOpenReferral && team?.loanOfficer && team?.loanOfficer?.id
        ? {
            displayName:
              team && team?.loanOfficer && team?.loanOfficer.name
                ? team?.loanOfficer.name
                : '',
            photoUrl: team?.loanOfficer?.photoUrl || '',
          }
        : {
            displayName: '',
            photoUrl: '',
          },
    [isFullOrOpenReferral, team]
  );

  const borrowerDisplayName = useMemo(
    () =>
      formatBorrowerDisplayName(
        borrower?.fullName || '',
        borrower?.firstName || '',
        borrower?.lastName || ''
      ),
    [borrower]
  );
  const coBorrowerDisplayName = useMemo(
    () =>
      formatBorrowerDisplayName(
        coborrower?.fullName || '',
        coborrower?.firstName || '',
        coborrower?.lastName || ''
      ),
    [coborrower]
  );

  const updatedBlurb = useMemo(() => {
    return formatUpdatedBlurb(loanStatusDates, isInProgress);
  }, [loanStatusDates, isInProgress]);

  const content = isFullOrOpenReferral ? (
    <div className="h-full flex flex-col justify-between">
      <div className="pl-6 pr-3 flex w-full items-start justify-between">
        <div className="flex flex-row">
          {!!lastCompletedStageFormatted && (
            <p data-testid="loan-summary-last-completed-stage">
              <span className="font-bold leading-3 text-sm bg-primary-body-base text-white rounded px-1 py-[2px]">
                {lastCompletedStageFormatted}
              </span>
            </p>
          )}
          {!!dispositionStatusFormatted && (
            <p data-testid="loan-summary-last-disposition-status">
              <span className="font-bold leading-3 ml-2 border border-primary-body-base text-sm rounded px-1 py-[2px]">
                {dispositionStatusFormatted}
              </span>
            </p>
          )}
        </div>
        {active && <ChevronRight className="mr-2" />}
      </div>
      <div
        className={classNames('h-full px-6 flex flex-col justify-between', {
          'justify-between': loanOfficer.displayName,
          'gap-2': verticalGap,
        })}
      >
        <h2
          className="font-bold text-xl text-primary-body-base m-0"
          data-testid="loan-summary-purchase-price"
        >
          {!!loan.loanDetails?.purchasePriceAmount &&
            new Intl.NumberFormat('en-US', numberFormatOptions).format(
              loan.loanDetails?.purchasePriceAmount || 0
            )}
        </h2>
        <div>
          <h4
            className="text-base font-bold truncate m-0"
            data-testid="loan-summary-borrower"
          >
            {borrowerDisplayName}
          </h4>
          {isOpenReferral && (
            <ContactLine
              email={borrower?.email}
              phone={borrower?.phone}
              mode={mode}
            />
          )}
          {coborrower && coBorrowerDisplayName && !isOpenReferral && (
            <h4
              className="text-base font-bold truncate m-0"
              data-testid="loan-summary-coborrower"
            >
              {coBorrowerDisplayName}
            </h4>
          )}
          {!isOpenReferral && (
            <div className="text-base" data-testid="loan-summary-property">
              <Address
                emptyString="Pending sales contract"
                property={property}
              />
            </div>
          )}
        </div>
        {updatedBlurb && (
          <p className="text-xs m-0" data-testid="loan-summary-updated-date">
            {updatedBlurb}
          </p>
        )}
        {loanOfficer.displayName && (
          <div className="flex flex-row gap-2 text-center items-center ">
            {process.env.NODE_ENV !== 'test' && (
              <CircleAvatar
                photoUrl={(loanOfficer && loanOfficer.photoUrl) || ''}
                alt={(loanOfficer && loanOfficer.displayName) || ''}
                size={8}
                className="group-hover:scale-110 transition-transform duration-200"
              />
            )}
            <span className="text-primary-body-base text-base text-left">
              Working with {loanOfficer.displayName}
            </span>
          </div>
        )}
        {isOpenReferral && (
          <BorrowerConsentAlert
            loan={loan}
            severity="warning"
            showClasses={['mt-3']}
            mode={mode}
          />
        )}
      </div>
    </div>
  ) : (
    <div className="px-6 h-full">
      <div className="flex flex-col gap-3 justify-between h-full">
        <div className="flex flex-col gap-2">
          <p>
            <span className="font-bold leading-3 text-sm text-white bg-primary-body-base rounded px-1 py-[2px]">
              {isInProgress ? 'Upcoming closing' : 'Closed'}
            </span>
          </p>
          {property?.city && property?.state && (
            <h1 className="font-bold text-xl text-primary-body-base">
              {capitalCase(property?.city || '')}, {property?.state}
            </h1>
          )}
        </div>
        <BorrowerConsentAlert
          loan={loan}
          severity="warning"
          showClasses={['mt-3']}
        />
      </div>
    </div>
  );

  if (!isFullOrOpenReferral) {
    return (
      <div
        className={`border border-secondary-inactive-lighter py-4 rounded bg-white ${className}`}
        data-testid={`closing-summary-${guid}`}
        role="gridcell"
      >
        {content}
      </div>
    );
  }
  return (
    <button
      onClick={() => {
        if (isRunningInShellApp && isSendMessageToShellApp) {
          setIsPrevUrlDashboard(true);
          sendMessageToShellApp(closingsMessage);
        } else {
          navigate(pathname);
        }
      }}
      data-testid={`closing-summary-${guid}`}
      role="gridcell"
    >
      <div
        className={`h-full border border-secondary-inactive-lighter pt-5 pb-4 rounded cursor-pointer hover:border-secondary-action-dark hover:bg-secondary-action-lightest active:scale-95 transition duration-75 bg-white text-left ${className}`}
      >
        {content}
      </div>
    </button>
  );
};

export { LoanSummaryTile };
