import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { AlertSeverity } from 'types';

type BannerProps = {
  show: boolean;
  type: AlertSeverity;
  children: React.ReactNode;
  dataTestId?: string;
  showClasses: string[];
  rounded?: boolean;
};

const Banner = (props: BannerProps) => {
  const { show, type, children, rounded = true } = props;
  const baseClassnames = useMemo(() => {
    const classes = [
      'text-sm',
      'transition-all',
      'duration-500',
      'ease-in-out',
      ...(rounded ? ['rounded'] : []),
    ];
    switch (type) {
      case 'success':
        classes.push('bg-status-ok-lightest');
        break;
      case 'error':
        classes.push('bg-status-critical-lightest');
        break;
      case 'info':
        classes.push('bg-status-info-lightest');
        break;
    }
    return classes;
  }, [rounded, type]);
  const hideClasses = useMemo(() => ['opacity-0', 'h-0', 'max-h-0'], []);
  const showClasses = useMemo(
    () => ['opacity-1000', 'h-auto', 'p-5', ...props.showClasses],
    [props]
  );
  const hiddenClasses = useMemo(
    () => [...baseClassnames, ...hideClasses],
    [baseClassnames, hideClasses]
  );
  const visibleClasses = useMemo(
    () => [...baseClassnames, ...showClasses],
    [baseClassnames, showClasses]
  );

  const [className, setClassName] = useState(hiddenClasses);

  useEffect(() => {
    if (show) {
      setClassName(visibleClasses);
    } else {
      setClassName(hiddenClasses);
    }
  }, [show, visibleClasses, hiddenClasses]);

  return (
    <div
      className={classNames(className)}
      {...(props?.dataTestId && { 'data-testid': props.dataTestId })}
    >
      {show && children}
    </div>
  );
};

export { Banner };
