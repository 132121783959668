import { Action, ActionType } from './actions';
import type { UserContextType } from './UserContext';

export function reducer(state: UserContextType, action: Action) {
  switch (action.type) {
    case ActionType.SET_LOANS_ERROR:
      return { ...state, loansError: action.payload };
    case ActionType.SET_BANNER_INDEX:
      return { ...state, bannerIndex: action.payload };
    case ActionType.SET_LOANS_LOADING:
      return { ...state, loansLoading: action.payload, loansError: null };
    case ActionType.SET_LOAN_STATS:
      return { ...state, loanStats: action.payload };
    case ActionType.SET_USER_LOADING:
      return { ...state, userLoading: action.payload };
    case ActionType.LOGOUT:
      return {
        ...state,
        loansError: null,
        user: undefined,
        loanOfficers: undefined,
        loansLoading: false,
        loans: [],
      };
    case ActionType.MARKETING_REQUEST:
      return { ...state, marketingRequest: action.payload };
    case ActionType.SET_MLS_ASSOCIATIONS:
      return { ...state, mlsAssociations: action.mlsAssociations };
    case ActionType.SET_UNIQUE_LOAN_OFFICERS:
      return {
        ...state,
        uniqueActiveLoanOfficers: action.uniqueActiveLoanOfficers,
      };
    case ActionType.SET_LASTAUTHCHECK:
      return { ...state, lastAuthCheck: action.payload };
    default:
      return { ...state };
  }
}
