import { useState } from 'react';

const Carousel = (props: {
  cards: React.ReactElement[];
  slug: string;
  heightClass: string;
}) => {
  const { cards, slug, heightClass } = props;
  const [cardShowingIndex, setCardShowingIndex] = useState(0);
  return (
    <div>
      <div className="relative w-full">
        <div className={`relative overflow-hidden ${heightClass}`}>
          {cards.map((card, index) => (
            <div
              key={`carousel-card-${slug}-${index}`}
              className={`duration-700 ease-in-out absolute inset-0 transition-transform transform ${
                index === cardShowingIndex
                  ? 'translate-x-0'
                  : cardShowingIndex - index > 1 ||
                    (index < cardShowingIndex && cardShowingIndex - index < 4)
                  ? '-translate-x-full'
                  : 'translate-x-full'
              } ${index === cardShowingIndex ? 'z-20' : 'z-10'} ${
                Math.abs(index - cardShowingIndex) === 2 ||
                Math.abs(index - cardShowingIndex) === 3
                  ? 'hidden'
                  : ''
              }`}
            >
              <div className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                {card}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-3 flex justify-center gap-6">
        {cards.map((card, index) => (
          <button
            key={`carousel-indicator-${slug}-${index}`}
            className={`w-3 h-3 rounded-full inset-0 transition-transform transform ${
              index === cardShowingIndex
                ? 'bg-primary-body-base'
                : 'bg-[#929394]'
            }`}
            aria-current={index === cardShowingIndex ? 'true' : 'false'}
            aria-label={`Slide ${index + 1}`}
            onClick={() => {
              setCardShowingIndex(index);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export { Carousel };
