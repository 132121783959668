import type { Banner } from 'types';

const filterOutExpiredBanners = (banners: Banner[]): Banner[] =>
  banners.filter((banner) => {
    if (banner.hasOwnProperty('endOn') && banner?.endOn) {
      return new Date(banner.endOn).getTime() > new Date(Date.now()).getTime();
    }
    return true;
  });

export { filterOutExpiredBanners };
