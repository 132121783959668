import { requestWithTimeoutAndOktaLogin } from './util';
import type { ApiResponse, JsonApiType, Banner } from 'types';
import { inLocalTestModeByEnvVar } from 'lib/util';
import { testBanners } from 'lib/test-data/banner';

interface JsonApiBanner extends JsonApiType {
  attributes: {
    message: string;
    endOn?: string;
  };
}

const useTestData = inLocalTestModeByEnvVar('BANNERS');

const formatJsonApiBanners = (banners: JsonApiBanner[]): Banner[] =>
  banners.map(
    (item: JsonApiBanner) =>
      ({
        id: item.id,
        message: item.attributes?.message || '',
        endOn: item.attributes?.endOn || '',
      } as Banner)
  );

interface ErrorObject {
  code: string;
  title: string;
}

interface ErrorResponse {
  errors: ErrorObject[];
}

interface GetPageResponse extends ApiResponse {
  banners?: Banner[];
  serverError?: ErrorResponse;
}

const getBanners = async (): Promise<GetPageResponse> => {
  if (useTestData) {
    return {
      ok: true,
      banners: [testBanners.pilotGreeting, testBanners.pilotEnding],
    };
  }
  try {
    const response = await requestWithTimeoutAndOktaLogin('/v1/banners');
    if (!response.response?.ok || response.response.status !== 200) {
      throw response.responseObj;
    }

    let banners: Banner[] = [];

    if (Array.isArray(response.responseObj?.data)) {
      banners = formatJsonApiBanners(response.responseObj.data);
    }
    return { ok: true, banners };
  } catch (responseObj: any) {
    return {
      ok: false,
      error: responseObj,
      serverError: responseObj,
    };
  }
};

const dismissBanner = async (id: string): Promise<ApiResponse> => {
  const url = '/v1/banners/' + id;
  try {
    const response = await requestWithTimeoutAndOktaLogin(url, {
      method: 'DELETE',
    });
    if (!response.response?.ok || response.response.status !== 204) {
      throw response.responseObj;
    }
    return { ok: true };
  } catch (responseObj: any) {
    return {
      ok: false,
      error: responseObj,
    };
  }
};

export { dismissBanner, getBanners };
