import { ReactNode, createContext, useContext, useReducer } from 'react';
import { useCookie } from 'react-use';
import useLocalStorageState from 'use-local-storage-state';

import { AppContextProps, LoanOfficerYext, TenantConfig } from 'types';
import { getTenantConfig } from 'config';
import { inLocalTestModeByEnvVar } from 'lib/util';

type AppContextStateType = {
  activeLOs: LoanOfficerYext[];
  tenant: TenantConfig;
  mobileNavIsOpen: boolean;
  isRunningInShellApp: boolean;
};
enum ActionType {
  SET_ACTIVE_LOS = 'SET_ACTIVE_LOS',
  SET_MLS_ASSOCIATIONS = 'SET_MLS_ASSOCIATIONS',
  SET_MOBILE_NAV_IS_OPEN = 'SET_MOBILE_NAV_IS_OPEN',
  SET_IS_RUNNING_IN_SHELL_APP = 'SET_IS_RUNNING_IN_SHELL_APP',
  SET_TENANT = 'SET_TENANT',
}

type Action =
  | { type: ActionType.SET_ACTIVE_LOS; activeLOs: LoanOfficerYext[] }
  | {
      type: ActionType.SET_MOBILE_NAV_IS_OPEN;
      mobileNavIsOpen: boolean;
    }
  | {
      type: ActionType.SET_IS_RUNNING_IN_SHELL_APP;
      isRunningInShellApp: boolean;
    }
  | {
      type: ActionType.SET_TENANT;
      tenant: TenantConfig;
    };

/*
  cookies set by the shell app (a native iOS/Android app) to tell Agent Advantage that we are running in an in-app browser
*/
const SHELL_APP_COOKIE_NAME = 'is_shell_app';
const SHELL_APP_COOKIE_VALUE = 'true';

const reducer = (state: AppContextStateType, action: Action) => {
  switch (action.type) {
    case ActionType.SET_ACTIVE_LOS:
      return { ...state, activeLOs: action.activeLOs };
    case ActionType.SET_MOBILE_NAV_IS_OPEN:
      return { ...state, mobileNavIsOpen: action.mobileNavIsOpen };
    case ActionType.SET_IS_RUNNING_IN_SHELL_APP:
      return { ...state, isRunningInShellApp: action.isRunningInShellApp };
    case ActionType.SET_TENANT:
      return { ...state, tenant: action.tenant };
    default:
      return { ...state };
  }
};

const inShellAppMode = inLocalTestModeByEnvVar('SHELL_APP');

const appContextDefaultProps = {
  tenant: getTenantConfig(),
  mobileNavIsOpen: false,
  setMobileNavIsOpen: () => {},
  setActiveLOs: (activeLOs: LoanOfficerYext[]) => {},
  activeLOs: [] as LoanOfficerYext[],
  teDeepLink: '',
  setTeDeepLink: () => {},
  isPrevUrlDashboard: false,
  setIsPrevUrlDashboard: () => {},
  isRunningInShellApp: inShellAppMode ? true : false,
  detectShellApp: () => {},
};

export const AppContext = createContext<AppContextProps>(
  appContextDefaultProps
);

export function AppProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, appContextDefaultProps);
  const { tenant, activeLOs, mobileNavIsOpen, isRunningInShellApp } = state;
  const setActiveLOs = (activeLOs: LoanOfficerYext[]) => {
    dispatch({ type: ActionType.SET_ACTIVE_LOS, activeLOs });
  };
  const setMobileNavIsOpen = (mobileNavIsOpen: boolean) => {
    dispatch({ type: ActionType.SET_MOBILE_NAV_IS_OPEN, mobileNavIsOpen });
  };
  const [teDeepLink, setTeDeepLink] = useLocalStorageState('te', {
    defaultValue: '',
  });

  const [isPrevUrlDashboard, setIsPrevUrlDashboard] = useLocalStorageState(
    'prevURL',
    {
      defaultValue: false,
    }
  );
  const [shellAppCookieValue] = useCookie(SHELL_APP_COOKIE_NAME);
  const detectShellApp = () => {
    dispatch({
      type: ActionType.SET_IS_RUNNING_IN_SHELL_APP,
      isRunningInShellApp: inShellAppMode
        ? true
        : shellAppCookieValue === SHELL_APP_COOKIE_VALUE,
    });
  };

  return (
    <AppContext.Provider
      value={{
        tenant,
        mobileNavIsOpen,
        setMobileNavIsOpen,
        activeLOs,
        setActiveLOs,
        teDeepLink,
        setTeDeepLink,
        isRunningInShellApp,
        detectShellApp,
        isPrevUrlDashboard,
        setIsPrevUrlDashboard,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default function useAppContext() {
  return useContext(AppContext);
}
