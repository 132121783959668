import useCurrentUser from 'contexts/UserContext';
import { Button } from 'Components';
import { ReactComponent as UserAvatar } from 'Assets/icons/user-avatar.svg';
export const LoggedOutNav = () => {
  const { login } = useCurrentUser();

  return (
    <div className="flex">
      <div className="md:flex md:flex">
        <div className="md:flex hidden navbar-item pr-0">
          <Button
            type="quaternary"
            to="/create-account"
            className={['hover:no-underline', 'pr-3']}
          >
            <div className="text-sm">Create account</div>
          </Button>
        </div>
        <div className="md:flex hidden navbar-item pr-0">
          <Button
            type="quaternary"
            onClick={() => login()}
            className={['hover:no-underline']}
          >
            <div className="text-sm">Log in</div>
          </Button>
        </div>
      </div>
      <div className="flex md:hidden cursor-pointer" onClick={() => login()}>
        <UserAvatar />
      </div>
    </div>
  );
};
