import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { LoggedOutNav } from 'Components';
import { RateAgentsLogo } from '../NavBar/RateAgentsLogo';
import styles from 'Components/LoginHeader/LoginHeader.module.css';
import { useEffect, useState } from 'react';

type HeaderProps = {
  showNav?: boolean;
};

export const Header = (props: HeaderProps) => {
  const { showNav = true } = props;
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <nav
        role="navigation"
        aria-label="navigation"
        className={classNames([
          'px-4',
          'bg-white',
          'w-full',
          'z-50',
          'sticky',
          'top-0',
          'flex',
          'justify-center',
          scrolled && 'drop-shadow-md',
        ])}
      >
        <div
          className={classNames([
            'h-17',
            'w-full',
            'flex',
            'items-center',
            'lg:min-h-68',
            'max-w-1170px',
            'fullhd:max-w-1392px',
            'justify-between',
            'lg:align-center',
          ])}
        >
          <div className="flex justify-between items-center">
            <Link
              to="/"
              className={`${styles.logoLink} flex items-center justify-start absolute top-0 h-17 w-52 md:relative md:relative md:top-0 md:h-auto`}
            >
              <RateAgentsLogo />
            </Link>{' '}
          </div>
          {showNav && (
            <div className="flex">
              <LoggedOutNav />
            </div>
          )}
        </div>
      </nav>
    </>
  );
};
