import {
  AbridgedLoanOfficer,
  ApiResponse,
  createLeadResponseType,
  PersonFirstLast,
  PersonFirstLastEmailPhone,
  ReferralFormType,
  UnauthReferFormValues,
} from 'types';
import { post } from '.';
import { extractJsonApiErrors, requestWithTimeout, sanitize } from './util';

type GuidResponse = {
  data: { type: 'loan' | 'dm-invite' | 'email'; id: string };
  errors?: any;
};
type LoInviteData = {
  loanOfficerId: number;
  loanOfficerCompany: string;
  email: string;
  displayName: string;
};
interface Borrower extends PersonFirstLast {
  phone?: string;
  email?: string;
  langPref?: string;
}
type CoBorrower = {
  firstName?: string;
  lastName?: string;
};
type CreateLeadRequestBody = {
  loanOfficerId: number;
  loanOfficerCompany: string;
  borrower: Borrower;
  coBorrower: CoBorrower;
};
type InviteRequestBody = {
  guid: string;
  loanOfficer: LoInviteData;
  borrower: Borrower;
  memo: string;
};

async function createLead(
  referral: ReferralFormType,
  loanOfficer: AbridgedLoanOfficer
): Promise<createLeadResponseType> {
  const loanOfficerId = loanOfficer?.loanOfficerId || 0;
  const errorMessage =
    'Unable to send referral. Please retry or contact administrator.';
  const requestBody: CreateLeadRequestBody = {
    loanOfficerId,
    loanOfficerCompany: loanOfficer?.company || '',
    borrower: {
      firstName: referral.clientFirstName,
      lastName: referral.clientLastName,
    },
    coBorrower: {
      firstName: referral.coBorrowerFirstName,
      lastName: referral.coBorrowerLastName,
    },
  };
  if (referral.langPref) {
    requestBody.borrower.langPref = referral.langPref;
  }
  if (referral.clientPhone) {
    requestBody.borrower.phone = referral.clientPhone;
  }
  if (referral.clientEmail) {
    requestBody.borrower.email = referral.clientEmail;
  }
  try {
    const response = await post(
      '/v1/loans',
      JSON.stringify(sanitize(requestBody))
    );
    const results = (await response.json()) as GuidResponse;
    const guid = results?.data?.id;
    if (guid) {
      const dmInviteBody: InviteRequestBody = {
        guid: guid,
        loanOfficer: {
          loanOfficerId,
          loanOfficerCompany: loanOfficer?.company || '',
          email: loanOfficer?.email || '',
          displayName: loanOfficer?.displayName || '',
        },
        borrower: {
          firstName: referral.clientFirstName,
          lastName: referral.clientLastName,
          phone: referral.clientPhone,
          email: referral.clientEmail,
        },
        memo: referral.clientMessage,
      };
      if (referral.langPref) {
        dmInviteBody.borrower.langPref = referral.langPref;
      }
      const sanitizedInviteBody = sanitize(dmInviteBody);
      await Promise.all([
        dmInviteResponse(sanitizedInviteBody),
        emailLeadToLo(sanitizedInviteBody),
      ]);
      return { ok: response.ok, guid: guid };
    } else {
      if (results.errors?.length) {
        console.log(results.errors[0]?.title);
        return {
          ok: false,
          error: errorMessage,
        };
      }
      return {
        ok: false,
        error: errorMessage,
      };
    }
  } catch (err) {
    return {
      ok: false,
      error: errorMessage,
    };
  }
}

async function dmInviteResponse(dmInviteBody: InviteRequestBody) {
  return await post('/v1/dm-invite', JSON.stringify(dmInviteBody));
}
async function emailLeadToLo(dmInviteBody: InviteRequestBody) {
  return await post('/v1/email-lead-to-lo', JSON.stringify(dmInviteBody));
}

type UnauthLeadType = {
  loanOfficerId: number;
  loanOfficerCompany: string;
  agent: PersonFirstLastEmailPhone;
  borrower: PersonFirstLastEmailPhone;
  coBorrower: PersonFirstLast;
};

const createUnauthLead = async (
  values: UnauthReferFormValues
): Promise<ApiResponse> => {
  const payload: UnauthLeadType = {
    loanOfficerId:
      typeof values.loanOfficerId === 'string'
        ? parseInt(values.loanOfficerId)
        : values.loanOfficerId,
    loanOfficerCompany: values.loanOfficerCompany,
    agent: {
      firstName: values.agentFirstName,
      lastName: values.agentLastName,
      phone: values.agentPhone,
      email: values.agentEmail,
    },
    borrower: {
      firstName: values.borrowerFirstName,
      lastName: values.borrowerLastName,
      phone: values.borrowerPhone,
      email: values.borrowerEmail,
    },
    coBorrower: {
      firstName: values.coborrowerFirstName || '',
      lastName: values.coborrowerLastName || '',
    },
  };
  try {
    const response = await requestWithTimeout('/v1/referrals', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(sanitize(payload)),
    });

    if (!response?.ok || response.status !== 204) {
      throw response;
    }
    return { ok: true };
  } catch (responseObj: any) {
    const jsonApiErrors = await extractJsonApiErrors(responseObj);
    return {
      ok: false,
      jsonApiErrors,
    };
  }
};

export { createLead, createUnauthLead };
