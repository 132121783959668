import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import WarningTriangleIcon from 'Assets/icons/warning-triangle.svg';
import ErrorTriangleIcon from 'Assets/icons/error-triangle.svg';
import CircleTickIcon from 'Assets/icons/check-tick-circle.svg';
import InformationCircleIcon from 'Assets/icons/information-circle.svg';
import LoaderIcon from 'Assets/icons/loader-red.svg';
import type { AlertMessage, AlertSeverity, AlertType } from 'types';
import { CloseButton } from 'Components';

export interface AlertProps {
  show: boolean;
  severity: AlertSeverity;
  type?: AlertType;
  message: AlertMessage;
  dataTestId?: string;
  showClasses: string[];
  controlLabel?: string;
  controlOnClick?: () => void;
  controlPosition?: 'inline' | 'right';
  loading?: boolean;
  isCloseIcon?: boolean;
  paddingY?: string;
  customIcon?: string;
  messageClassName?: string;
}
const Alert = (props: AlertProps) => {
  const {
    severity,
    type = 'inline',
    show,
    message,
    controlLabel = '',
    controlOnClick,
    dataTestId = '',
    loading = false,
    controlPosition = 'right',
    isCloseIcon = false,
    paddingY = '3',
    customIcon,
    messageClassName,
  } = props;

  const hasControl = useMemo(
    () => typeof controlOnClick === 'function',
    [controlOnClick]
  );

  const baseClassnames = useMemo(() => {
    const classes = [
      'border',
      'rounded',
      `py-${paddingY}`,
      'px-3',
      'text-sm',
      'transition-all',
      'duration-500',
      'ease-in-out',
    ];
    switch (severity) {
      case 'error':
        classes.push(
          ...['border-status-critical-base', 'bg-status-critical-lightest']
        );
        break;
      case 'info':
        classes.push(
          ...['border-secondary-action-base', 'bg-status-info-lightest']
        );
        break;
      case 'warning':
        classes.push(
          ...['border-status-warning-base', 'bg-status-warning-lightest']
        );
        break;
      case 'success':
        classes.push(...['border-status-ok-base', 'bg-status-ok-lightest']);
        break;
    }
    if (hasControl) {
      classes.push(...['flex', 'justify-between']);
    } else {
      classes.push(...['flex', 'justify-start']);
    }
    if (type === 'banner') {
      classes.push('items-start');
    } else {
      classes.push('items-start md:items-center');
    }
    return classes;
  }, [hasControl, severity, type, paddingY]);

  const icon = useMemo(() => {
    if (customIcon) return customIcon;

    let icon;
    switch (severity) {
      case 'error':
        icon = ErrorTriangleIcon;
        break;
      case 'warning':
        icon = WarningTriangleIcon;
        break;
      case 'success':
        icon = CircleTickIcon;
        break;
      case 'info':
        icon = InformationCircleIcon;
        break;
    }
    return icon;
  }, [severity, customIcon]);
  const iconMessageClassname = useMemo(() => {
    let classname = 'flex justify-center gap-3';
    return type === 'inline'
      ? `${classname} items-start`
      : `${classname} items-start`;
  }, [type]);
  const iconClassname = useMemo(
    () => (type === 'banner' ? 'mt-1' : ''),
    [type]
  );
  const controlClassname = useMemo(() => {
    let classname = 'text-base ';
    switch (severity) {
      case 'error':
        classname +=
          'text-status-critical-base hover:text-primary-brand-base hover:underline font-bold';
        break;
      case 'warning':
        classname +=
          'text-status-warning-dark hover:text-status-warning-dark hover:underline font-bold';
        break;
      case 'success':
        classname +=
          'text-turqoise-dark hover:text-turqoise-dark hover:underline font-bold';
        break;
    }
    return classname;
  }, [severity]);
  const hideClasses = useMemo(() => ['opacity-0', 'm-0'], []);
  const showClasses = useMemo(
    () => ['opacity-1000', 'h-auto', 'p-5', ...props.showClasses],
    [props]
  );
  const hiddenClasses = useMemo(
    () => [...baseClassnames, ...hideClasses],
    [baseClassnames, hideClasses]
  );
  const visibleClasses = useMemo(
    () => [...baseClassnames, ...showClasses],
    [baseClassnames, showClasses]
  );

  const [className, setClassName] = useState(hiddenClasses);

  useEffect(() => {
    if (show) {
      setClassName(visibleClasses);
    } else {
      setClassName(hiddenClasses);
    }
  }, [show, visibleClasses, hiddenClasses]);

  return (
    <div className={classNames(className)} data-testid={dataTestId}>
      <div className={iconMessageClassname}>
        {loading ? (
          <img src={LoaderIcon} className="animate-spin" alt="Loading..." />
        ) : (
          <img
            src={icon}
            className={iconClassname}
            alt=""
            width={20}
            height={20}
          />
        )}
        <div className={messageClassName || 'text-base'}>
          {message}{' '}
          {hasControl && !!controlLabel && controlPosition === 'inline' && (
            <button className={controlClassname} onClick={controlOnClick}>
              {controlLabel}
            </button>
          )}
        </div>
      </div>
      {hasControl &&
        controlPosition === 'right' &&
        (isCloseIcon ? (
          <CloseButton
            className={controlClassname}
            onClick={controlOnClick}
            iconStyle="w-4"
            dataTestId="alert-close-icon"
          />
        ) : (
          controlLabel && (
            <button
              className={controlClassname}
              onClick={controlOnClick}
              data-testid="alert-close-label"
            >
              {controlLabel}
            </button>
          )
        ))}
    </div>
  );
};
export { Alert };
