import LoaderIcon from 'Assets/icons/loader-quaternary.svg';

const LoaderSmall = () => (
  <div className="flex items-center gap-2 font-bold text-primary-body-base font-semibold text-base">
    <img
      src={LoaderIcon}
      alt="Loading..."
      className="animate-spin h-4 w-4"
      role="alert"
      aria-busy="true"
    />
    <span>Loading...</span>
  </div>
);

export { LoaderSmall };
