import type { TenantColors } from 'types';

type NameDictionary = {
  [key: string]: string;
};

let tenantNames: NameDictionary = {};

if (window?.config?.tenants) {
  for (var tenant in window.config.tenants) {
    if (window.config.tenants[tenant].name) {
      tenantNames[tenant] = window.config.tenants[tenant].name;
    }
  }
} else {
  tenantNames = {
    GRI: 'Guaranteed Rate',
    GRA: 'Guaranteed Rate Affinity, LLC',
    PR: 'Proper Rate, LLC',
    OP: 'Origin Point, LLC',
    CW: 'Citywide Home Loans, LLC',
    CERTAINTY: 'Certainty Home Lending',
  };
}

const emailDomainIsCertainty = (email: string): boolean => {
  if (email && email !== '') {
    const certaintyMatches = email.match(/certaintyhomelending.com$/);
    return Array.isArray(certaintyMatches) && certaintyMatches.length > 0;
  }
  return false;
};

function tenantName(abbr: string = '', email: string = ''): string | undefined {
  if (email && email !== '') {
    if (emailDomainIsCertainty(email)) {
      return tenantNames.CERTAINTY;
    }
  }
  const key = abbr.toUpperCase();
  return tenantNames[key];
}

const tenantNameOrDefault = (
  companyAbbr: string,
  defaultCompanyName: string,
  email?: string
): string => tenantName(companyAbbr, email) || defaultCompanyName;

function getHostTenant(host: string = ''): string {
  const hostLowercase = host.toLowerCase();
  let tenantFromHost = '';
  let tenants: any = {};
  if (window?.config?.tenants) {
    tenants = window.config.tenants;
  } else {
    tenants = {
      GRI: {
        name: 'Guaranteed Rate',
        hosts: {
          dev: ['agents.gr-dev.com', 'agents.dev.saas.rate.com'],
          qa: [],
          prod: 'agents.rate.com',
        },
      },
      GRA: {
        name: 'Guaranteed Rate Affinity, LLC',
        hosts: {
          dev: ['agents-gra.gr-dev.com', 'agents-gra.dev.saas.rate.com'],
          qa: [],
          prod: 'agents.grarate.com',
        },
      },
      PR: {
        name: 'Proper Rate, LLC',
        hosts: {
          dev: ['agents.prate-dev.com', 'agents-pr.dev.saas.rate.com'],
          qa: [],
          prod: 'agents.properrate.com',
        },
      },
      OP: {
        name: 'Origin Point, LLC',
      },
      CW: {
        name: 'Citywide Home Loans, LLC',
        hosts: {
          dev: ['agents-cw.dev.saas.rate.com'],
          qa: [],
          prod: ['agents.citywidehomeloans.com', 'agents.citywidehm.com'],
        },
      },
      CERTAINTY: {
        name: 'Certainty Home Lending',
        hosts: {
          dev: ['agents-certainty.dev.saas.rate.com'],
          qa: [],
          prod: 'agents.certaintyhomelending.com',
        },
      },
    };
  }
  for (var tenant in tenants) {
    if (tenants[tenant].hosts) {
      for (var env in tenants[tenant].hosts) {
        if (Array.isArray(tenants[tenant].hosts[env])) {
          if (tenants[tenant].hosts[env].includes(hostLowercase)) {
            tenantFromHost = tenant;
            break;
          }
        } else {
          if (tenants[tenant].hosts[env] === hostLowercase) {
            tenantFromHost = tenant;
            break;
          }
        }
      }
    }
  }
  return tenantFromHost;
}

const getEnvTenant = (): string =>
  process.env.REACT_APP_COMPANY
    ? process.env.REACT_APP_COMPANY.toUpperCase()
    : '';

const getTenant = (host: string = ''): string =>
  getEnvTenant() || getHostTenant(host) || 'GRI';

const defaultTenantColors: TenantColors = {
  primary: {
    body: {
      base: 'primary-body-base',
      dark: 'primary-body-dark',
    },
    brand: {
      base: 'primary-brand-base',
      lightest: 'primary-brand-lightest',
      dark: 'primary-brand-dark',
      outline: 'primary-brand-base',
    },
  },
  secondary: {
    action: {
      base: 'secondary-action-base',
      dark: 'secondary-action-base',
    },
    inactive: {
      light: 'secondary-inactive-light',
    },
  },
};

export {
  defaultTenantColors,
  emailDomainIsCertainty,
  tenantName,
  getTenant,
  tenantNameOrDefault,
};
