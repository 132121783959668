import { ReactNode } from 'react';

import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Spinner } from 'Components';

import type { ButtonTypes } from 'types';

type ButtonProps = {
  as?: 'button' | 'submit' | 'reset';
  type?: ButtonTypes;
  children: ReactNode | string;
  className?: string[];
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: any;
  externalHref?: string;
  targetBlank?: boolean;
  to?: string;
  icon?: string;
  dataTestId?: string;
  padding?: string[];
  fullWidth?: boolean;
  loadingWord?: string;
  tabIndex?: number;
};

export function Button(props: ButtonProps) {
  const primary = [
    'text-white',
    'hover:text-white',
    'focus:text-white',
    'active:scale-90',
    'disabled:scale-100',
    'rounded',
    'border-transparent',
    'no-underline',
    'font-bold',
    'bg-primary-brand-base',
    'hover:bg-primary-brand-dark',
    'active:bg-primary-brand-dark',
    'disabled:bg-secondary-inactive-light',
    'disabled:hover:bg-secondary-inactive-light',
  ];

  const secondary = [
    'font-bold',
    'bg-white',
    'rounded',
    'border',
    'text-primary-brand-base',
    'border-primary-brand-base',
    'active:scale-90',
    'disabled:scale-100',
    'no-underline',
    'hover:bg-primary-brand-lightest',
    'hover:text-primary-brand-dark',
    'hover:border-primary-brand-dark',
    'disabled:border',
    'disabled:border-secondary-inactive-light',
    'disabled:text-secondary-inactive-light',
    'disabled:hover:bg-white',
  ];

  const tertiary = [
    'text-secondary-action-base',
    'hover:text-secondary-action-base',
    'font-bold',
    'hover:underline',
    'active:scale-90',
    'disabled:scale-100',
    'disabled:text-secondary-inactive-light',
    'disabled:hover:text-secondary-inactive-light',
    'disabled:hover:no-underline',
  ];

  const redPrimary = [
    'text-white',
    'hover:text-white',
    'font-bold',
    'rounded',
    'font-semibold',
    'bg-primary-brand-base',
    'hover:bg-primary-brand-dark',
    'active:bg-primary-brand-dark',
    'active:scale-90',
    'disabled:scale-100',
    'no-underline',
    'px-5',
    'py-3',
  ];

  const redSecondary = [
    'font-bold',
    'bg-white',
    'rounded',
    'border',
    'text-primary-brand-base',
    'border-primary-brand-base',
    'active:scale-90',
    'disabled:scale-100',
    'no-underline',
    'px-5',
    'py-3',
    'text-primary-brand-base',
    'border-primary-brand-base',
    'hover:bg-primary-brand-lightest',
    'hover:border-primary-brand-dark',
    'hover:text-primary-brand-dark',
  ];

  const blueSquare = [
    'text-white',
    'rounded',
    'hover:text-white',
    'focus:text-white',
    'no-underline',
    'whitespace-nowrap',
    'px-4',
    'py-2',
    'text-secondary-action-base',
    'border-secondary-action-base',
    'border',
    'hover:bg-secondary-action-base',
    'focus:bg-secondary-action-base',
  ];

  const quaternary = [
    'text-primary-body-base',
    'hover:text-primary-body-dark',
    'font-bold',
    'hover:underline',
    'active:scale-90',
    'disabled:scale-100',
    'disabled:text-secondary-inactive-light',
  ];

  const designs = {
    primary,
    secondary,
    redPrimary,
    redSecondary,
    tertiary,
    squared: [],
    link: [
      'hover:underline',
      'text-secondary-action-base',
      'text-base',
      'font-bold',
      'hover:text-secondary-input-medium',
      'active:scale-90',
    ],
    whiteLink: ['underline', 'text-white'],
    greyLink: ['underline', 'text-primary-body-base'],
    redLink: ['underline', 'text-primary-brand-base'],
    blueSquare,
    login: [...redPrimary, 'w-full', 'lg:w-2/3', 'text-xl'],
    signup: [
      ...primary,
      'bg-primary-brand-base',
      'hover:bg-primary-brand-dark',
      'focus:bg-primary-brand-dark',
      'w-full',
      'lg:w-auto',
      'py-4',
      'px-9',
      'font-bold',
      'text-xl',
    ],
    quaternary,
  };

  let paddingDefault: string[] = [];
  if (props.type) {
    if (props.type === 'login') {
      paddingDefault = ['px-5', 'py-3'];
    } else if (['primary', 'secondary', 'redPrimary'].includes(props.type)) {
      paddingDefault = ['px-5', 'py-3'];
    }
  }

  const {
    as = 'button',
    type = 'primary',
    children,
    className,
    isDisabled = false,
    isLoading = false,
    onClick,
    externalHref,
    to,
    icon,
    dataTestId,
    padding = paddingDefault,
    targetBlank = true,
    fullWidth,
    loadingWord = 'Loading',
    tabIndex,
  } = props;

  const styles = [
    {
      'cursor-not-allowed': isLoading,
    },
    ...designs[type],
    ...padding,
    'disabled:cursor-not-allowed',
    'inline-block',
    'inline-flex',
    'justify-center',
    'cursor-pointer',
    className,
  ];
  if (type === 'greyLink') {
    if (isLoading) {
      styles.push(...['bg-neutral-200', 'hover:bg-neutral-200']);
    } else {
      styles.push('hover:bg-neutral-100');
    }
  }
  if (fullWidth) {
    styles.push('w-full');
  }

  const classList = classNames(styles);

  if (to) {
    return (
      <Link
        to={to}
        className={classList}
        onClick={() => {
          if (typeof onClick === 'function') {
            onClick();
          }
        }}
        data-testid={dataTestId}
      >
        {children}
        {icon && <img src={icon} alt="" className="ml-2" />}
      </Link>
    );
  }
  if (externalHref) {
    const aProps: { [key: string]: string } = {
      href: externalHref,
      className: classList,
      rel: 'noreferrer',
      onClick,
    };
    if (dataTestId) {
      aProps['data-testid'] = dataTestId;
    }
    if (typeof targetBlank === 'boolean' && targetBlank) {
      aProps.target = '_blank';
    }
    return <a {...aProps}>{children}</a>;
  }
  type ButtonProps = {
    type?: 'button' | 'submit' | 'reset';
    className: string;
    onClick: () => void;
    'data-testid'?: string;
    disabled?: boolean;
    width?: string;
  };
  const buttonProps: ButtonProps = {
    type: as,
    className: classList,
    onClick,
    'data-testid': dataTestId,
    ...(tabIndex ? { tabIndex } : {}),
  };
  if (isDisabled) {
    buttonProps.disabled = isDisabled;
  }
  const spinnerMargin = ['tertiary', 'quaternary'].includes(type) ? 1 : 2;
  return (
    <button {...buttonProps}>
      {isLoading ? (
        <div className="flex items-center">
          <Spinner type={type} />
          <span className={`ml-${spinnerMargin}`}>{loadingWord}&hellip;</span>
        </div>
      ) : (
        children
      )}
    </button>
  );
}
