import { useCallback, useState } from 'react';

import { Banner, Button, Dialog } from 'Components';
import { openHouse as openHouseApi } from 'api';

interface OpenHouseDeleteConfirmationProps {
  open: boolean;
  id: string;
  onClose: () => void;
}

const OpenHouseDeleteConfirmation = (
  props: OpenHouseDeleteConfirmationProps
) => {
  const { open, id, onClose } = props;

  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const buttonClasses = [
    'w-full',
    'md:w-52',
    'md:px-14',
    'text-[20px]',
    'font-black',
    'py-4',
    'self-center',
    'whitespace-nowrap',
  ];

  const handleClose = useCallback(() => {
    if (!loading) {
      onClose();
    }
  }, [loading, onClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={loading}
      data-testid="open-house-delete-confirmation-dialog"
    >
      <div className="min-w-full md:w-[912px] px-4 md:px-12 pb-12">
        <div className="flex flex-col justify-start md:items-center md:justify-center">
          <div className="md:w-[672px] flex flex-col">
            <h1 className="font-black text-3xl md:text-[32px] mt-0 text-primary-body-base tracking-normal justify-self-start">
              Are you sure you want to delete this listing?
            </h1>
            <span>
              Doing so will remove this link and all access to attendance date,
              and it cannot be recovered.
            </span>
            <Banner
              show={showError}
              type="error"
              dataTestId="oh-delete-error-banner"
              showClasses={['my-3']}
            >
              <div className="flex flex-row gap-3 justify-between">
                <div>
                  <h4 className="text-lg font-bold mb-0 text-primary-body-base">
                    Error
                  </h4>
                  <p className="text-base font-medium text-primary-body-base">
                    Sorry, there was a problem deleting this open house.
                  </p>
                </div>
              </div>
            </Banner>
            <div className="flex justify-center gap-6 mt-6 flex-col md:flex-row">
              <Button
                type="secondary"
                onClick={handleClose}
                isDisabled={loading}
                className={buttonClasses}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                isLoading={loading}
                isDisabled={loading}
                onClick={async () => {
                  setLoading(true);
                  setShowError(false);
                  const results = await openHouseApi.deleteOpenHouse(id);
                  setLoading(false);
                  if (results.ok) {
                    onClose();
                  } else {
                    setShowError(true);
                  }
                }}
                dataTestId="dt-oh-delete-confirmation"
                className={buttonClasses}
              >
                {loading ? 'Deleting ...' : 'Yes, delete'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export { OpenHouseDeleteConfirmation };
