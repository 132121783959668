import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { styled, useTheme } from '@mui/material/styles';
import { BackButton, CloseButton } from 'Components';
import classNames from 'classnames';

interface ExtendedDialogProps extends DialogProps {
  showBackButton?: boolean;
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
}

const GrDialog = styled(Dialog)<ExtendedDialogProps>(({ theme }) => ({
  '& .MuiDialog-paper': {
    [theme.breakpoints.up('md')]: {
      boxShadow: '0px 4px 24px 4px #24272940',
      borderRadius: 4,
      verticalAlign: 'top',
      marginTop: 85,
    },
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgba(0,0,0,0.15)',
  },
}));

const Modal = ({ showBackButton, onClose, ...props }: ExtendedDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (typeof onClose === 'function') {
      onClose({}, 'escapeKeyDown');
    }
  };

  return (
    <GrDialog
      {...props}
      scroll="body"
      fullScreen={fullScreen}
      maxWidth={false}
      onClose={onClose}
    >
      {showBackButton ? (
        <BackButton
          onClick={handleClose}
          className="text-secondary-action-base py-1.5 items-center px-4 pt-4"
          dataTestId="dialog-back-button"
        />
      ) : (
        <div className="flex justify-end pt-4 pl-6 pr-4">
          <CloseButton
            onClick={handleClose}
            dataTestId="dialog-close-icon-button"
          />
        </div>
      )}

      {props.children}
    </GrDialog>
  );
};

export { Modal as Dialog };
