import { Header1, Header4 } from 'Components';
import useCurrentUser from 'contexts/UserContext';
import { showAverageCtc, zeroStats } from 'Components/ClosingStats/util';

const HiUser = (props: { size: 1 | 4 }) => {
  const { size } = props;
  const { user, loanStats } = useCurrentUser();
  const showAvgCtc = showAverageCtc(loanStats || zeroStats);
  const classNames = ['mt-0', 'break-words'];
  if (showAvgCtc) {
    classNames.push('mb-1');
  }

  const text = user?.firstName ? `Hi, ${user?.firstName}` : 'Hi';
  return size === 1 ? (
    <Header1 classes={classNames}>{text}</Header1>
  ) : (
    <Header4>{text}</Header4>
  );
};

export { HiUser };
