import { Dialog, Header3 } from 'Components';
import { EmailVerificationJwtClaims } from 'types';

interface ExpiredTokenModalProps {
  open: boolean;
  emailVerificationJwtClaims?: EmailVerificationJwtClaims | null;
  onClose: () => void;
}

export const ExpiredTokenModal = ({
  open,
  emailVerificationJwtClaims,
  onClose,
}: ExpiredTokenModalProps) => (
  <Dialog
    open={open}
    onClose={(_evt, reason) => reason !== 'backdropClick' && onClose()}
    transitionDuration={500}
  >
    <div className="px-8 pb-8 md:w-[32rem]">
      <Header3 classes={['mb-4', 'md:text-xl']}>
        Verification link expired
      </Header3>
      <p className="text-base text-primary-body-base">
        Looks like this link has already expired.
        <br />
        We've just sent you a new one. Please check your email inbox at{' '}
        <strong>{emailVerificationJwtClaims?.sub}</strong>.
      </p>
    </div>
  </Dialog>
);
