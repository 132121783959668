import { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './LoggedOutMobileNav.module.css';
import useAppContext from 'contexts/AppContext';

type MobileMenuProps = {
  children: ReactNode | string;
};

const MobileMenu = (props: MobileMenuProps) => {
  const { mobileNavIsOpen } = useAppContext();

  return (
    <>
      <div
        className={classNames(
          'transition-all',
          'duration-300',
          'ease-in-out',
          'md:hidden',
          mobileNavIsOpen ? 'block' : 'hidden',
          styles.overlay
        )}
      />
      <nav
        role="navigation"
        aria-label="dropdown navigation"
        className={classNames(
          'inset-x-0',
          'bg-white',
          'text-primary-body-base',
          'z-40',
          'pt-8',
          'h-screen',
          'w-screen',
          'absolute',
          'transition-all',
          'duration-300',
          'ease-in-out',
          'left-0',
          mobileNavIsOpen ? styles.top325 : styles.topMinus100vh,
          mobileNavIsOpen ? 'opacity-100' : 'opacity-0',
          'md:hidden',
          'px-4',
          'is-transparent',
          styles.inner
        )}
      >
        <div className="container">{props.children}</div>
      </nav>
    </>
  );
};

export { MobileMenu };
