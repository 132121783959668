import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { TestableProps } from 'types';

export interface LinkButtonProps extends TestableProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // setting this overrides the back button's behavior with custom functionality
  disabled?: boolean;
  className?: string;
  title?: string;
}

// a button that displays itself as a blue-text link.
export const LinkButton = ({
  onClick,
  disabled = false,
  className = '',
  title,
  children,
  dataTestId: testId,
}: PropsWithChildren<LinkButtonProps>) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        'font-bold text-base text-secondary-action-base hover:text-secondary-action-base hover:underline active:scale-90 cursor-pointer',
        className
      )}
      data-testid={testId}
      disabled={disabled}
      title={title}
    >
      {children}
    </button>
  );
};
