import type { SupportRequestType, SendSupportRequestResponse } from 'types';
import { sanitize } from './util';

export async function sendSupportRequest(
  supportRequest: SupportRequestType
): Promise<SendSupportRequestResponse> {
  try {
    const response = await fetch('/v1/support-request', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sanitize(supportRequest)),
    });

    const responseObject = await response.json();

    const sendSupportRequestResponse: SendSupportRequestResponse = {
      statusCode: response.status,
      ok: response.status === 500 ? false : true,
    };

    if (!sendSupportRequestResponse.ok) {
      sendSupportRequestResponse.responseStatusText = response.statusText;
    }

    if (responseObject.hasOwnProperty('message')) {
      sendSupportRequestResponse.message = responseObject.message;
    }
    if (responseObject.hasOwnProperty('status')) {
      sendSupportRequestResponse.status = responseObject.status;
    }

    return sendSupportRequestResponse;
  } catch (error) {
    return { ok: false, error };
  }
}
