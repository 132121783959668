import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Autocomplete from '@mui/material/Autocomplete';
import Paper, { PaperProps } from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import type { LoanOfficerYext } from 'types';
import useAppContext from 'contexts/AppContext';
import { loanOfficers as loApi } from 'api';
import { muiAutocompleteStyles } from 'styles/mui';
import { disabledFilter } from 'styles/image';
import { ReactComponent as ChevronDown } from 'Assets/icons/chevron-down-small.svg';
import { ReactComponent as SearchIcon } from 'Assets/icons/magnifying-glass-search.svg';
import { muiInputFilledStyles, muiInputOutlinedStyles } from 'styles/mui';
import { profileCircleClasses } from 'styles/image';
import { ReactComponent as UserAvatar } from 'Assets/icons/user-avatar.svg';

const GrStyledPaper = styled(Paper)<PaperProps>(
  ({ theme }) => muiAutocompleteStyles
);

interface LoanOfficerUniqKey extends LoanOfficerYext {
  key?: string;
}

type LoanOfficerAutocompleteProps = {
  autoFocus?: boolean;
  loid?: number;
  company?: string;
  placeholder?: string;
  label?: string;
  setSelectedLO: (lo: LoanOfficerYext) => void;
  sx?: object;
  isCompanyDisplay?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText: string;
  tabIndex?: number;
};

const LoanOfficerAutocomplete = (props: LoanOfficerAutocompleteProps) => {
  const { activeLOs, setActiveLOs } = useAppContext();

  const [uniqKeyLOs, setUniqKeyLOs] = useState<LoanOfficerUniqKey[]>([]);
  const [value, setValue] = useState<LoanOfficerUniqKey>();

  const {
    autoFocus = false,
    setSelectedLO,
    loid,
    company,
    placeholder = 'Search for loan officer by name',
    label = '',
    disabled = true,
    error = false,
    helperText,
    tabIndex,
  } = props;

  const variant = useMemo(
    () => (label === '' ? 'outlined' : 'filled'),
    [label]
  );

  const GrStyledPopper = useCallback((props) => {
    return (
      <Popper
        {...{
          ...props,
          ...{
            popperOptions: {
              modifiers: [
                {
                  name: 'offset',
                  options: { offset: [0, 10] },
                },
              ],
            },
          },
        }}
      />
    );
  }, []);

  const circleClasses = profileCircleClasses(12);

  const GrStyledTextField = styled(TextField)<TextFieldProps>(({ theme }) =>
    variant === 'outlined' ? muiInputOutlinedStyles : muiInputFilledStyles
  );

  const GrTextField = useCallback(
    (props: TextFieldProps) => {
      const fieldProps = { ...props };
      fieldProps.InputLabelProps = {
        ...fieldProps.InputLabelProps,
      };
      if (label !== '') {
        fieldProps.InputProps = {
          ...fieldProps.InputProps,
          ...{ disableUnderline: true },
        };
      }
      return <GrStyledTextField color="secondary" fullWidth {...fieldProps} />;
    },
    [label, GrStyledTextField]
  );

  useEffect(() => {
    if (activeLOs.length === 0) {
      const fetchActiveLOs = async () => {
        try {
          const response = await loApi.get();
          setActiveLOs(response.loanOfficers || []);
        } catch (error) {
          console.error(error);
        }
      };
      fetchActiveLOs();
    }
  }, [activeLOs, setActiveLOs]);

  const lastFirstNameConcat = (loanOfficer: LoanOfficerUniqKey) =>
    loanOfficer.lastName.toUpperCase() + loanOfficer.firstName.toUpperCase();

  useEffect(() => {
    const keyedLOs: LoanOfficerUniqKey[] = [];
    let i = 0;
    for (const activeLO of activeLOs) {
      const keyedLO = {
        ...activeLO,
        key: `${activeLO.company}-${activeLO.id}-${i}`,
      };
      keyedLOs.push(keyedLO);
      if (
        loid &&
        loid === activeLO.id &&
        company &&
        company === activeLO.company
      ) {
        setValue(keyedLO);
      }
      i++;
    }
    const sortLoanOffciers = (loanOfficers: LoanOfficerUniqKey[]) =>
      loanOfficers.sort((a, b) => {
        const nameA = lastFirstNameConcat(a);
        const nameB = lastFirstNameConcat(b);
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    setUniqKeyLOs(sortLoanOffciers(keyedLOs));
  }, [activeLOs, company, loid]);

  const filterOptions = (
    options: LoanOfficerUniqKey[],
    state: { getOptionLabel: any; inputValue: string }
  ) => {
    if (state.inputValue) {
      const filteredOptions: LoanOfficerUniqKey[] = [];
      options.forEach((option) => {
        if (
          `${option.firstName}${option.lastName}${option.email}${option.cellPhone}${option.officePhone}${option.address?.address1}${option.address?.address2}${option.address?.city}${option.address?.state}${option.address?.zip}`
            .toLowerCase()
            .includes(state.inputValue.toLowerCase())
        ) {
          filteredOptions.push(option);
        }
      });
      return filteredOptions;
    } else {
      return options;
    }
  };

  const formatCityState = (option: LoanOfficerUniqKey): string =>
    [option.address?.city, option.address?.state].filter(Boolean).join(', ');

  const loanOfficerLabel = (option: LoanOfficerUniqKey): string => {
    const cityState = formatCityState(option);
    return option && option.lastName
      ? `${option.firstName} ${option.lastName}, ${option.title}${
          cityState ? ` (${cityState})` : ''
        }`
      : '';
  };
  const formatDropdownOption = (option: LoanOfficerUniqKey) => {
    const cityState = formatCityState(option);
    return (
      <div className="flex flex-row gap-4 flex-nowrap text-gray-ravenswood">
        {option.photoUrl ? (
          <img
            src={option.photoUrl}
            alt={`${option.firstName[0].toUpperCase()}${option.lastName[0].toUpperCase()}`}
            width="24"
            height="24"
            className={classNames(circleClasses, 'shrink-0')}
          />
        ) : (
          <UserAvatar
            className={classNames(['w-12', 'h-12', 'shrink-0'])}
            style={disabledFilter}
            width={48}
            height={48}
          />
        )}
        <div>
          <p className="inline md:block">
            <strong>
              {option.firstName} {option.lastName}
            </strong>
            {option.title && (
              <span className="text-sm md:text-base">, {option.title}</span>
            )}
          </p>
          <p className="inline text-sm font-normal md:block">
            <span className="md:hidden">, </span>
            {cityState}
          </p>
        </div>
      </div>
    );
  };
  return (
    <div data-testid="loan-officer-search-combo-box">
      <Autocomplete
        id="loan-officer-autocomplete"
        options={uniqKeyLOs}
        getOptionLabel={(option) => loanOfficerLabel(option)}
        filterOptions={filterOptions}
        renderOption={(props, option) => (
          <li {...props} key={option.key}>
            {formatDropdownOption(option)}
          </li>
        )}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <GrTextField
              {...params}
              color="secondary"
              fullWidth
              label={label}
              placeholder={placeholder}
              error={error}
              disabled={disabled}
              helperText={error ? helperText : ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      className="w-5 h-5 ml-1"
                      style={disabled ? disabledFilter : {}}
                    />
                  </InputAdornment>
                ),
                autoFocus: autoFocus || false,
                ...(tabIndex ? { tabIndex } : {}),
              }}
              variant={variant}
            />
          </div>
        )}
        onChange={(event: React.SyntheticEvent, selectedLO) => {
          const abridgedLO = { ...selectedLO };
          if (abridgedLO && abridgedLO.hasOwnProperty('key')) {
            delete abridgedLO.key;
          }
          setSelectedLO(abridgedLO as LoanOfficerYext);
          setValue(selectedLO as LoanOfficerUniqKey);
        }}
        PaperComponent={GrStyledPaper}
        PopperComponent={GrStyledPopper}
        popupIcon={<ChevronDown />}
        forcePopupIcon={true}
        value={
          value ?? {
            key: 'null',
            id: 0,
            address: {
              address1: '',
              address2: '',
              city: '',
              state: '',
              zip: '',
            },
            firstName: '',
            lastName: '',
            title: '',
            company: '',
            photoUrl: '',
            officePhone: '',
            cellPhone: '',
            email: '',
          }
        }
        disabled={disabled}
        disableClearable={false}
      />
    </div>
  );
};

export { LoanOfficerAutocomplete };
