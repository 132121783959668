import { styled } from '@mui/material/styles';
import { Drawer, DrawerProps } from '@mui/material';
import { BackButton, CloseButton } from 'Components';

type GrDrawerProps = {
  children: React.ReactNode;
  open: boolean;
  anchor?: 'left' | 'top' | 'right' | 'bottom';
  backclick: () => void;
  isCloseButton?: boolean;
  isCloseDisabled?: boolean;
  backDrop?: boolean;
  dataTestId?: string;
};
const GrDrawerContainer = styled(Drawer)<DrawerProps>(({ theme }) => ({
  '& .MuiDrawer-paper': {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
    [(theme.breakpoints.up('sm'), theme.breakpoints.up('md'))]: {
      width: 500,
    },
    overflow: 'hidden',
  },
}));

const GrDrawer = (props: GrDrawerProps) => {
  const {
    open,
    anchor,
    backclick,
    isCloseButton = false,
    isCloseDisabled = false,
    backDrop = true,
    dataTestId,
  } = props;
  const handleClose = () => {
    if (!isCloseDisabled) {
      backclick();
    }
  };
  return (
    <GrDrawerContainer
      anchor={anchor}
      open={open}
      onClose={handleClose}
      BackdropProps={{ invisible: backDrop }}
    >
      <div
        className="p-4 z-auto"
        style={{
          width: '100%',
          background: 'white',
          zIndex: 100,
        }}
        data-testid={dataTestId}
      >
        {isCloseButton ? (
          <div className="float-right">
            <CloseButton onClick={handleClose} disabled={isCloseDisabled} />
          </div>
        ) : (
          <div className="float-left">
            <BackButton onClick={handleClose} disabled={isCloseDisabled} />
          </div>
        )}
      </div>
      <div className="overflow-scroll">{props.children}</div>
    </GrDrawerContainer>
  );
};

export { GrDrawer as Drawer };
