import useAppContext from 'contexts/AppContext';

import styles from './MobileNavToggle.module.css';

const MobileNavToggle = () => {
  const { mobileNavIsOpen, setMobileNavIsOpen } = useAppContext();

  function mobileNavToggleOpen() {
    if (!mobileNavIsOpen) {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
    setMobileNavIsOpen(!mobileNavIsOpen);
  }

  return (
    <div data-testid="mobile-nav-toggle" className="md:hidden">
      <button
        data-target="menu"
        className={`${styles.navbarBurger} ${
          mobileNavIsOpen ? styles.isActive : ''
        }`}
        onClick={mobileNavToggleOpen}
      >
        <span />
        <span />
        <span />
      </button>
    </div>
  );
};

export { MobileNavToggle };
