export type ProfileSizeClassesSize = 8 | 12 | 18 | 19 | 20 | 28 | 32;

const profileSizeClasses = (size: number) => {
  let sizeClasses = [];
  switch (size) {
    case 8:
      sizeClasses = ['w-8', 'h-8'];
      break;
    case 12:
      sizeClasses = ['w-12', 'h-12'];
      break;
    case 18:
      sizeClasses = ['w-18', 'h-18'];
      break;
    case 19:
      sizeClasses = ['w-19', 'h-19'];
      break;
    case 20:
      sizeClasses = ['w-20', 'h-20'];
      break;
    case 28:
      sizeClasses = ['w-18', 'h-18', 'md:w-28', 'md:h-28'];
      break;
    case 32:
    default:
      sizeClasses = ['w-32', 'h-32', 'md:w-32', 'md:h-32'];
      break;
  }
  return sizeClasses;
};

const imageBaseClasses = ['overflow-hidden', 'object-cover', 'border-0'];
const profileCircleClasses = (size: ProfileSizeClassesSize) => [
  'rounded-full',
  ...imageBaseClasses,
  ...profileSizeClasses(size),
];

const logoRectClasses = [
  'w-40',
  'max-w-[132px]',
  'md:max-w-[192px]',
  ...imageBaseClasses,
];

const roundedRectClasses = ['rounded', 'md:max-w-[192px]', ...imageBaseClasses];

const disabledFilter = {
  filter:
    'invert(94%) sepia(14%) saturate(0%) hue-rotate(189deg) brightness(78%) contrast(91%)',
};

export {
  disabledFilter,
  logoRectClasses,
  profileCircleClasses,
  profileSizeClasses,
  roundedRectClasses,
};
