import { Button } from 'Components';
import useAppContext from 'contexts/AppContext';
import { sendMessageToShellApp } from 'lib/shell-app';
import { useNavigate } from 'react-router-dom';

const MarketingButton = () => {
  const { isRunningInShellApp } = useAppContext();
  const navigate = useNavigate();
  return (
    <>
      <Button
        type="secondary"
        onClick={() => {
          isRunningInShellApp
            ? sendMessageToShellApp({ type: 'OPEN_AGENTS_MARKETING' })
            : navigate('/marketing-request');
        }}
        className={['w-full md:w-234 md:!px-3']}
        dataTestId="marketing-button"
      >
        <span className="block md:hidden">Marketing materials</span>
        <span className="hidden md:block">Request marketing materials</span>
      </Button>
    </>
  );
};

export { MarketingButton };
