import { requestWithTimeout, requestWithTimeoutAndOktaLogin } from './util';
import type {
  ApiResponse,
  JsonApiType,
  LoanOfficer,
  LoanOfficerYext,
} from 'types';

interface JsonApiLoanOfficer extends JsonApiType {
  attributes: {
    loanOfficerId: string;
    company: string;
    firstName: string;
    lastName: string;
    address?: {
      line1?: string;
      line2?: string;
      city?: string;
      state?: string;
      zip?: string;
    };
    title?: string;
    officePhone?: string;
    mobilePhone?: string;
    email: string;
    employeeStatus: string;
    photoUrl?: string;
  };
}

const formatJsonApiLoanOfficers = (los: JsonApiLoanOfficer[]): LoanOfficer[] =>
  los.map(
    (item: JsonApiLoanOfficer) =>
      ({
        company: item.attributes.company,
        employeeId: parseInt(item.attributes.loanOfficerId),
        email: item.attributes.email,
        firstName: item.attributes.firstName,
        lastName: item.attributes.lastName,
        fullName: `${item.attributes.firstName} ${item.attributes.lastName}`,
        nmlsId: item.id,
        officePhone: item.attributes?.officePhone || '',
        city: item.attributes?.address?.city || '',
        state: item.attributes?.address?.state || '',
        title: item.attributes?.title || '',
        photoUrl: item.attributes?.photoUrl || '',
      } as LoanOfficer)
  );

type SearchParams = {
  searchTerm: string;
  offset: string;
};
interface ErrorObject {
  code: string;
  title: string;
}

interface ErrorResponse {
  errors: ErrorObject[];
}

interface SearchResponse extends ApiResponse {
  loanOfficers?: LoanOfficer[];
  serverError?: ErrorResponse;
}

async function search({
  searchTerm,
  offset,
}: SearchParams): Promise<SearchResponse> {
  const url =
    '/v1/loan-officers/search?search-term=' + searchTerm + '&offset=' + offset;
  try {
    const response = await requestWithTimeoutAndOktaLogin(url);
    if (!response.response?.ok || response.response.status !== 200) {
      throw response.responseObj;
    }

    let loanOfficers: LoanOfficer[] = [];

    if (Array.isArray(response.responseObj?.data)) {
      loanOfficers = formatJsonApiLoanOfficers(response.responseObj.data);
    }
    return { ok: true, loanOfficers };
  } catch (responseObj: any) {
    return {
      ok: false,
      error: responseObj,
      serverError: responseObj,
    };
  }
}

interface JsonApiLoanOfficerYext extends JsonApiType {
  attributes: {
    address: {
      address1: string;
      address2: string;
      city: string;
      state: string;
      zip: string;
    };
    email: string;
    firstName: string;
    lastName: string;
    loanOfficerId: number;
    company: string;
    title: string;
    photoUrl: string;
    cellPhone: string;
    officePhone: string;
  };
}

const formatJsonApiLoanOfficersYext = (
  los: JsonApiLoanOfficerYext[]
): LoanOfficerYext[] =>
  los.map(
    (item: JsonApiLoanOfficerYext) =>
      ({
        ...item.attributes,
        id: item.attributes.loanOfficerId,
      } as LoanOfficerYext)
  );

interface GetActiveLoanOfficersResponse extends ApiResponse {
  loanOfficers?: LoanOfficerYext[];
  serverError?: ErrorResponse;
}

const get = async (): Promise<GetActiveLoanOfficersResponse> => {
  try {
    const response = await requestWithTimeout('/v1/loan-officers');

    if (!response?.ok || response.status !== 200) {
      throw response;
    }

    const responseText = await response.clone().text();
    const responseObj = JSON.parse(responseText);
    if (!response?.ok) {
      throw responseObj || responseText;
    }
    let loanOfficers: LoanOfficerYext[] = [];

    if (Array.isArray(responseObj?.data)) {
      loanOfficers = formatJsonApiLoanOfficersYext(responseObj.data);
    }
    return { ok: true, loanOfficers };
  } catch (responseObj: any) {
    return {
      ok: false,
      error: responseObj,
      serverError: responseObj,
    };
  }
};

export { get, search };
