import { Button, Dialog, Header3 } from 'Components';
import { confirmationDialogConfig } from 'types';
import { ReactComponent as WarningTriangleIcon } from 'Assets/icons/warning-triangle.svg';

export const DeleteConfirmationDialog = (props: {
  confirmationConfig: confirmationDialogConfig;
  updateConfirmationConfig: (config: confirmationDialogConfig) => void;
}) => {
  const { confirmationConfig, updateConfirmationConfig } = props;

  const buttonClasses = [
    'w-48',
    'h-[60px]',
    'items-center',
    'px-0',
    'text-[20px]',
    'font-bold',
    'py-0',
    'self-center',
    'whitespace-nowrap',
  ];
  return (
    <Dialog
      open={confirmationConfig.show}
      onClose={() => {
        updateConfirmationConfig({
          ...confirmationConfig,
          show: false,
          isDelete: false,
        });
      }}
      data-testid="closings-filter-dialog"
    >
      <div className="min-w-full md:w-full med:w-[912px] overflow-x-hidden px-4 md:px-12 pb-12">
        <div className="flex flex-col justify-start md:items-center md:justify-center">
          <div className="md:w-[672px] flex flex-col items-center">
            {/* <img src={WarningTriangleIcon} alt="Loading..." /> */}
            <WarningTriangleIcon className="w-8 h-8" />
            <Header3
              classes={[
                'text-2xl',
                'justify-self-start',
                'text-center',
                'my-4',
              ]}
            >
              {confirmationConfig.text}
            </Header3>
            <span>{props.confirmationConfig.subText}</span>
            <div className="flex justify-center gap-6 mt-6 flex-col md:flex-row">
              <Button
                type="secondary"
                onClick={() => {
                  updateConfirmationConfig({
                    ...confirmationConfig,
                    show: false,
                    isDelete: false,
                  });
                }}
                className={buttonClasses}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  updateConfirmationConfig({
                    ...confirmationConfig,
                    show: false,
                    isDelete: true,
                  });
                }}
                dataTestId="dt-delete-confirmation"
                className={buttonClasses}
              >
                Yes, delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
