import { ReactNode } from 'react';
import classNames from 'classnames';

const Header3 = (props: { children: ReactNode; classes?: string[] }) => (
  <h3
    className={classNames(
      'font-bold',
      'text-xl',
      'md:text-2xl',
      'text-primary-body-base',
      'tracking-normal',
      ...(props.classes ?? [])
    )}
  >
    {props.children}
  </h3>
);

export { Header3 };
