import type { Banner } from 'types';

const testBanners: { [key: string]: Banner } = {
  pilotGreeting: {
    id: 'pilot-greeting',
    message:
      'Thanks for participating in the <strong>Rate Agents</strong> pilot! Your feedback is appreciated. Please note that profile changes made in Rate Agents will not be reflected in Agent Advantage. If you have questions, feel free to contact us at agents@rate.com.',
  },
  pilotEnding: {
    id: 'pilot-ending',
    message:
      'Thanks for participating in the Rate Agents pilot. The pilot will be ending on ??/??/????. After that time, you can access Rate Agents at ...',
  },
};

export { testBanners };
