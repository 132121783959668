import logo from 'Assets/logos/Companies/gri/logo.svg';

type RateAgentsLogoProps = {
  auth?: boolean;
};

export const RateAgentsLogo = (props: RateAgentsLogoProps) => {
  const { auth = false } = props;
  return (
    <div className="flex flex-row gap-1 items-left shrink-0">
      <img
        src={logo}
        alt="Rate Agents"
        className={auth ? 'h-[24px] w-[122px]' : 'h-[28px] w-[143px]'}
      />
    </div>
  );
};
