import { useNavigate } from 'react-router-dom';
import { Button } from 'Components';
import useAppContext from 'contexts/AppContext';
import { sendMessageToShellApp } from 'lib/shell-app';

const ReferButton = (props: { size?: 'large' | 'small' }) => {
  const { size = 'large' } = props;
  const { isRunningInShellApp } = useAppContext();
  const navigate = useNavigate();
  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          isRunningInShellApp
            ? sendMessageToShellApp({ type: 'OPEN_AGENTS_REFER_CLIENT' })
            : navigate('/refer-a-client');
        }}
        className={
          size === 'small' ? ['text-sm'] : ['w-full', 'md:w-auto', 'text-base']
        }
        dataTestId="refer-button"
        padding={size === 'small' ? ['px-2', 'py-2'] : ['px-10', 'py-4']}
      >
        <span className="whitespace-nowrap">Refer client</span>
      </Button>
    </>
  );
};

export { ReferButton };
