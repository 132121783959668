type ContactInfoProps = {
  src: string;
  alt: string;
  fontSize?: 'base' | 'sm';
  iconSize?: 4 | 5 | 6;
  applySizeToIcon?: boolean;
  children: React.ReactNode;
};

export const ContactInfo = (props: ContactInfoProps) => {
  const {
    src,
    alt,
    fontSize = 'base',
    iconSize = 4,
    applySizeToIcon = true,
    children,
  } = props;
  return (
    <div className="flex items-start gap-1 pt-1 break-inside-avoid-column">
      <div
        className={`shrink-0 w-${iconSize} flex items-center justify-center pt-0.5`}
      >
        <img
          className={applySizeToIcon ? `h-${iconSize} w-${iconSize}` : ''}
          src={src}
          alt={alt}
        />
      </div>
      <span className={`text-${fontSize} break-all`}>{children}</span>
    </div>
  );
};
