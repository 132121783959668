import {
  LOGIN_RETURN_PATH_LOCAL_STORAGE_KEY,
  UNACCEPTABLE_LOGIN_RETURN_PATHS,
} from './constants';

const login = (redirectPath?: string | null | undefined) => {
  if (redirectPath?.trim() && typeof redirectPath === 'string') {
    if (!UNACCEPTABLE_LOGIN_RETURN_PATHS.has(redirectPath)) {
      localStorage.setItem(LOGIN_RETURN_PATH_LOCAL_STORAGE_KEY, redirectPath);
    }
    // do nothing, if there exists an existing redirect path, leave it alone
  } else {
    localStorage.removeItem(LOGIN_RETURN_PATH_LOCAL_STORAGE_KEY);
  }

  const href = '/auth/login';
  window.location.href = href;
};

const logout = () => {
  window.location.href = '/auth/logout';
};

type WhoAmIResponse = {
  ok: boolean;
  claims?: {
    email: string;
    realtorId: string;
    borrowerId: string;
    name: string;
    exp: number;
  };
  error?: any;
};

const whoAmI = async (): Promise<WhoAmIResponse> => {
  try {
    const response = await fetch('/auth/whoami');
    const responseJson = await response.json();
    if (response.status !== 200) {
      throw responseJson;
    }
    const { claims } = responseJson;
    return {
      ok: true,
      claims: {
        email: claims.email,
        realtorId: claims.realtor_id,
        borrowerId: claims.borrower_id,
        name: claims.name,
        exp: claims.exp,
      },
    };
  } catch (error: any) {
    return {
      ok: false,
      error,
    };
  }
};

export { whoAmI, login, logout };
