import { ReactNode } from 'react';
import classNames from 'classnames';
import { lazily } from 'react-lazily';

import { BackButton, ConfigBanner, Header1 } from 'Components';

import useAppContext from 'contexts/AppContext';
import useWindowDimensions from 'lib/hooks/useWindowDimensions';
import type { NodeOrString } from 'types';

const { Footer } = lazily(() => import('Components/Footer'));
const { LoginHeader } = lazily(() => import('Components/LoginHeader'));

type LoggedInPageProps = {
  title?: string;
  sectionClassNames?: string[];
  header1ClassNames?: string[];
  addHorizontalMarginInMobile?: boolean;
  replaceHeaderWithBackButtonInShellApp?: boolean;
  addBackButton?: boolean;
  backButtonOnClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: NodeOrString;
  footerChildren?: ReactNode;
  isSectionClassNames?: boolean;
};

const LoggedInPage = (props: LoggedInPageProps) => {
  const {
    children,
    footerChildren,
    title,
    sectionClassNames = [],
    header1ClassNames = [],
    addHorizontalMarginInMobile = true,
    replaceHeaderWithBackButtonInShellApp = false,
    addBackButton = false,
    isSectionClassNames = true,
  } = props;

  const { width } = useWindowDimensions();
  const { isRunningInShellApp, mobileNavIsOpen } = useAppContext();

  if (isSectionClassNames) {
    if (addHorizontalMarginInMobile) {
      sectionClassNames.push('px-4');
      sectionClassNames.push('md:mx-0');
    }
    sectionClassNames.push(
      ...[
        'md:px-4',
        'md:mb-0',
        'md:p-5',
        'lg:pt-4',
        'w-full',
        'lg:w-913px',
        'lg:px-0',
        'lg:mx-auto',
        'flex',
        'flex-col',
      ]
    );
  }

  let showBackButton =
    addBackButton ||
    (replaceHeaderWithBackButtonInShellApp && isRunningInShellApp);

  const backButtonClasses = [
    'md:w-full',
    'md:flex',
    'flex-col',
    'items-center',
    'px-4',
    'md:px-8',
  ];
  if (isRunningInShellApp && !replaceHeaderWithBackButtonInShellApp) {
    backButtonClasses.push('hidden');
  } else {
    backButtonClasses.push('flex');
  }
  const containerClasses: string[] = [];
  if (isRunningInShellApp) {
    containerClasses.push('pt-4');
  }

  return (
    <div className={classNames(containerClasses)}>
      {!(replaceHeaderWithBackButtonInShellApp && isRunningInShellApp) && (
        <LoginHeader />
      )}
      <ConfigBanner />
      {showBackButton && (
        <div
          className={classNames(backButtonClasses)}
          data-testid="page-footer"
        >
          <nav className="block w-full mt-0 mb-4 fullhd:max-w-1440px">
            <BackButton onClick={props.backButtonOnClick} />
          </nav>
        </div>
      )}
      {(width >= 768 || !mobileNavIsOpen) && (
        <>
          <div className="flex bg-white">
            <section className={classNames(sectionClassNames)}>
              {title && (
                <Header1 classes={['mt-0', ...header1ClassNames]}>
                  {title}
                </Header1>
              )}
              {children}
            </section>
          </div>
          {!isRunningInShellApp && <Footer>{footerChildren}</Footer>}
        </>
      )}
    </div>
  );
};

export { LoggedInPage };
